import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import {PageContainer} from '../../../_ui/page';
import {ROUTES} from '../../../../router/routes';
import {useNavigate} from 'react-router-dom';
import {useUserRequests} from '../../../../_hooks/user-request/use-user-requests';
import {UserRequestAcceptedCaption} from '../_commons/captions';
import {useTranslation} from 'react-i18next';
import type { APISortingObject } from "../../../../_services/api/_helpers/api-list-types";
import { TextField } from "../../../_ui/form";
import PaginationDisplay from "../../../_ui/pagination";
import { debounce } from "lodash";

const ProUserRequestsTable = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<APISortingObject>([
    {
      name: "id",
      direction: "desc",
    },
  ]);
  const [search, setSearch] = React.useState("");

  const { userRequests, isLoading } = useUserRequests({
    page_size: 20,
    page_number: currentPage,
    filters: { category: ['professional'], search: [search] },
    sorting: sorting,
  });
  
  const data = {nodes: userRequests?.list || [], pageInfo:{totalPages:userRequests&&(Math.ceil(userRequests.total_count/userRequests.page_size)||0)}};

  // -------- Pagination / Sorting / Search
  
  const pagination = usePagination(
    data,
    {
      state: {
        page: 0,
        size: 20
      },
      onChange: onPaginationChange,
    },
    { isServer: true }
  );

  function onPaginationChange(action, state) {
    // refresh page with payload page value = action.payload.page +1
    setCurrentPage(action.payload.page + 1);
  }
  
  const handleSearch = (event) => {
    // refresh page with input search value = event.target.value
    setSearch(event.target.value);
  };

  const onInputChange = React.useMemo(
    () => debounce(handleSearch, 1000),
    [],
  );

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {},
      isServer: true,
    }
  );

  function onSortChange(action, state) {
    // refresh page with search state value = state.sortKey / state.reverse
    setSorting([
      {
        name: state.sortKey,
        direction: state.reverse ? "desc" : "asc",
      },
    ]);
  }

  // -- End Pagination / Sorting / Search

  const handleCellClick = (data) => {
    navigate(ROUTES.PRO_USER_REQUESTS(data.id));
  };


  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 50px minmax(160px, 1fr) 90px 100px 100px repeat(1, 140px) minmax(120px, 250px);
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  const COLUMNS = (handleCellClick, t) => [
    {
      label: 'ID',
      renderCell: (item) => item.id.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "id" },
    },
    {
      label: 'email',
      renderCell: (item) => item.user?.email.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "email" },
    },
    {
      label: 'Statut',
      renderCell: (item) => (
        <UserRequestAcceptedCaption
          className={item.accepted?.toString()}
        >
          {t([`enums.user_request.accepted.${item.accepted?.toString()}`, 'enums.user_request.accepted.null'])}
        </UserRequestAcceptedCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "accepted" },
    },
    {
      label: 'Prénom',
      renderCell: (item) => item.user?.first_name,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
    },
    {
      label: 'Nom',
      renderCell: (item) => item.user?.last_name,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
    },
    {
      label: 'RPPS',
      renderCell: (item) => item.user?.professional_number,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
    },
    {
      label: 'Date Demande',
      renderCell: (item) =>
        new Date(item.created_at).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "created_at" },
    },
  ];

  return (
    <>
      <TextField
          type="text"
          label="Rechercher un practitien"
          placeholder={""}
          onChange={(e) => {
            onInputChange(e);
          }}
        />
      <br />
      {isLoading ? <span className="spin-loader"></span> : <div style={{ textAlign: "left" }}>
        <CompactTable
          columns={COLUMNS(handleCellClick, t)}
          data={data}
          theme={theme}
          sort={sort}
          pagination={pagination}
          layout={{
            custom: true,
            horizontalScroll: true,
            fixedHeader: true,
          }}
        />
      </div>}
      <br />
      <PaginationDisplay pagination={pagination} data={data}/>
    </>
  );
};

const ProUserRequestsPage = () => (
  <PageContainer
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 20,
      height: 'calc(100svh - 120px)',
    }}
  >
    <h1>Demandes Pros</h1>
    <ProUserRequestsTable/>
  </PageContainer>
);

export default ProUserRequestsPage;