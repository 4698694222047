import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIUserRequest} from '../_helpers/api-types';

export async function getUserRequests(payload?: APIListPayload): Promise<APIListResponse<APIUserRequest>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIUserRequest>>>(
    `${API_NEMO_ROUTES.USER_REQUESTS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getUserRequest(id: number): Promise<APIUserRequest> {
  const { data } = await apiClient.get<AxiosResponse<APIUserRequest>>(
    `${API_NEMO_ROUTES.USER_REQUESTS}/${id}`
  );

  return data;
}

export async function updateUserRequest(formData : object): Promise<APIUserRequest> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIUserRequest>>(
    `${API_NEMO_ROUTES.USER_REQUESTS}/${id}`,
    formData
  );

  return data;
}

export async function newUserRequest(formData : object): Promise<APIUserRequest> {
  const { data } = await apiClient.post<object, AxiosResponse<APIUserRequest>>(
    `${API_NEMO_ROUTES.USER_REQUESTS}/new`,
    formData
  );

  return data;
}

export async function createUserRequest(formData : object): Promise<APIUserRequest> {
  const { data } = await apiClient.post<object, AxiosResponse<APIUserRequest>>(
    `${API_NEMO_ROUTES.USER_REQUESTS}`,
    formData
  );

  return data;
}

export async function deleteUserRequest(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.USER_REQUESTS}/${id}`
  );

  return data;
}