import { useMutation, useQueryClient } from "react-query";
import { AuthAPIService } from "../../_services/api";
import { userKeys } from "../user/queries";
import { APIListResponse } from "../../_services/api/_helpers/api-list-types";
import { APIUser } from "../../_services/api/auth/types";

export function useSignup() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess, data } = useMutation(
    userKeys.login(),
    (formData: object) => AuthAPIService.signup(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          userKeys.user,
          (user: APIListResponse<APIUser>) => (
            {
              ...(user || {})
            }
          )
        );
        return data;
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    signup: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
    data: data,
  };
}
