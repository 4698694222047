import styled from 'styled-components';
import Modal from '../modal';
import Button from '../button';
import { Legals_html } from '../../pages/legals';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--XL, 32px);
  background: var(--Colors-Neutral-white, #FFF);
  padding: 32px 44px 36px 44px;
  gap: 24px;
  flex-shrink: 0;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.25);
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  
  h1 {
    color: var(--Text-primary, #1D1D1B);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Mobile/friendlyTitle */
    font-family: "Mochiy Pop One";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 123.81% */
  }
`;

const ModalText = styled.div`
  color: var(--Text-primary-light, #636360);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2px;
  white-space: pre-line;
  
  b {
    color: #99D41C;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 10px;
`;

const CGUModal = ({isOpen, onClose}) => {
  if (!isOpen){
    return null;
  }

  return (
    // @ts-ignore
    <Modal
      withPadding
      isOpen={isOpen}
      onRequestClose={onClose}
      overlay={true}
    >
      <ModalContent>
        <ModalHeader>
          <h1>Condition Générales d'Utilisation</h1>
        </ModalHeader>
        <ModalButtons>
            <Button
                $display={'primary'}
                onClick={onClose}
              >
                Retour
              </Button>
        </ModalButtons>
        <Legals_html/>
      </ModalContent>
    </Modal>
  );
};

export default CGUModal;