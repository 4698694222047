import styled from "styled-components";
import { BackButton } from "../user";
import { ReactComponent as NavigateBeforeIcon } from "../../../../_icons/navigate_before.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";

const Container = styled.div`
  padding: 20px;
  overflow-y: scroll;
  text-align: left;
  h1 {
    span {
      color: black;
      font-weight: 600;
      font-size: 17px;
    }
  }
  h2 {
    span {
      color: black;
      font-weight: 500;
    }
  }
  p {
    text-align: left;
    font-family: "Noto Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: circle;
    list-style-position: inside;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
`;

const mentoring_legals_text = `
  Conditions Générales du Programme de
Parrainage NEMOConditions Générales du Programme de Parrainage NEMO1
1. Objet du Programme
Le programme de parrainage de NEMO permet aux utilisateurs existants d'inviter des confrères à rejoindre la
plateforme et de bénéficier d'avantages sous certaines conditions. Ce programme vise à encourager la diffusion de
lʼapplication auprès des professionnels de santé tout en récompensant les utilisateurs actifs.
2. Éligibilité
Pour être éligible au programme :
Le parrain doit être un utilisateur actif de NEMO avec un abonnement en cours.
Le filleul doit être un nouvel utilisateur nʼayant jamais créé de compte sur NEMO.
Un filleul ne peut être parrainé quʼune seule fois et par un seul parrain.
Lʼoffre de parrainage est destinée à un usage personnel et non commercial. Toute tentative de parrainage massif à
des fins lucratives ou frauduleuses entraînera lʼannulation des avantages obtenus et pourra conduire à la
suppression du compte concerné.
3. Fonctionnement
 Chaque utilisateur éligible dispose dʼun lien de parrainage unique accessible depuis son compte NEMO.
 Ce lien peut être partagé librement par tout moyen de communication.
 Pour bénéficier de lʼoffre, le filleul doit sʼinscrire en utilisant ce lien et souscrire à un abonnement payant.
 Une fois lʼinscription et le paiement validés, les récompenses sont attribuées automatiquement au parrain et au
filleul.
4. Récompenses
Parrain : obtient 1 mois offert par filleul inscrit et abonné.
Filleul : bénéficie dʼun mois offert.
Les récompenses :
Ne sont pas échangeables contre de lʼargent.
Ne sont attribuées quʼaprès validation du premier paiement du filleul.
Sont cumulables : un parrain peut obtenir plusieurs récompenses en parrainant plusieurs utilisateurs.
5. Limitations et Restrictions
Un utilisateur ne peut pas se parrainer lui-même.
Un parrain ne peut pas parrainer un ancien utilisateur ayant déjà disposé dʼun compte sur NEMO.
Toute tentative de fraude, incluant la création de faux comptes, lʼabus du programme ou toute manipulation visant
à obtenir des récompenses de manière déloyale, entraînera lʼannulation immédiate des avantages acquis et pourra
conduire à la résiliation du compte concerné.
NEMO se réserve le droit de suspendre temporairement ou définitivement le programme de parrainage à tout
moment sans préavis si des abus sont détectés ou pour des raisons techniques et commerciales.
6. Attribution des Récompenses
Les récompenses sont attribuées automatiquement sous un délai de [ex  48 heures] après validation du premier
paiement du filleul. En cas de retard ou dʼerreur, lʼutilisateur peut contacter le support de NEMO.
7. Modification et Fin du Programme
NEMO se réserve le droit de modifier ou mettre fin au programme de parrainage à tout moment. Toute modification
majeure des conditions sera notifiée aux utilisateurs via lʼapplication ou par email. En cas dʼarrêt du programme, les
récompenses obtenues avant la date de suppression resteront valides selon les conditions en vigueur.Conditions Générales du Programme de Parrainage NEMO2
8. Contact et Support
Pour toute question ou réclamation concernant le programme de parrainage, les utilisateurs peuvent contacter le
support via lʼadresse suivante : hello@nemoapp.fr.
BESOIN DʼAIDE ?
Pour toute autre question, consultez notre centre dʼaide (lien vers la FAQ ou contacter nous via WhatsApp, le
formulaire de support ou encore à lʼadresse hello@nemoapp.fr.

`;

export const Mentoring_legals_html = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1>Conditions Générales du Programme de
      Parrainage NEMO</h1>
      <br/>
      <h2>1. Objet du Programme</h2>
      <p><span>Le programme de parrainage de NEMO permet aux utilisateurs existants d'inviter des confrères à rejoindre la
plateforme et de bénéficier d'avantages sous certaines conditions. Ce programme vise à encourager la diffusion de
lʼapplication auprès des professionnels de santé tout en récompensant les utilisateurs actifs.</span></p>
      <h2>2. Éligibilité</h2>
      <span>Pour être éligible au programme :</span>
      <ul>
        <li><span>Le parrain doit être un utilisateur actif de NEMO avec un abonnement en cours.</span></li>
        <li><span>Le filleul doit être un nouvel utilisateur nʼayant jamais créé de compte sur NEMO.</span></li>
        <li><span>Un filleul ne peut être parrainé quʼune seule fois et par un seul parrain.</span></li>
        <li><span>Lʼoffre de parrainage est destinée à un usage personnel et non commercial. Toute tentative de parrainage massif à
des fins lucratives ou frauduleuses entraînera lʼannulation des avantages obtenus et pourra conduire à la
suppression du compte concerné.</span></li>
        </ul>
      <h2>3. Fonctionnement</h2>
      <ul>
        <li style={{listStyleType:"decimal"}}><span>Chaque utilisateur éligible dispose dʼun lien de parrainage unique accessible depuis son compte NEMO.</span></li>
        <li style={{listStyleType:"decimal"}}><span>Ce lien peut être partagé librement par tout moyen de communication.</span></li>
        <li style={{listStyleType:"decimal"}}><span>Pour bénéficier de lʼoffre, le filleul doit sʼinscrire en utilisant ce lien et souscrire à un abonnement payant.</span></li>
        <li style={{listStyleType:"decimal"}}><span>Une fois lʼinscription et le paiement validés, les récompenses sont attribuées automatiquement au parrain et au
        filleul.</span></li>
      </ul>
      <h2>4. Récompenses</h2>
      <ul>
        <li><span><b>Parrain :</b>obtient 1 mois offert par filleul inscrit et abonné.</span></li>
        <li><span><b>Filleul : </b>bénéficie dʼun mois offert.</span></li>
      </ul>
      <p><span>Les récompenses :</span></p>
      <ul>
        <li><span>Ne sont pas échangeables contre de lʼargent.</span></li>
        <li><span>Ne sont attribuées quʼaprès validation du premier paiement du filleul.</span></li>
        <li><span>Sont cumulables : un parrain peut obtenir plusieurs récompenses en parrainant plusieurs utilisateurs.</span></li>
      </ul>
      <h2>5. Limitations et Restrictions</h2>
      <ul>
        <li><span>Un utilisateur ne peut pas se parrainer lui-même.</span></li>
        <li><span>Un parrain ne peut pas parrainer un ancien utilisateur ayant déjà disposé dʼun compte sur NEMO.</span></li>
        <li><span>Toute tentative de fraude, incluant la création de faux comptes, lʼabus du programme ou toute manipulation visant
à obtenir des récompenses de manière déloyale, entraînera lʼannulation immédiate des avantages acquis et pourra
conduire à la résiliation du compte concerné.</span></li>
        <li><span>NEMO se réserve le droit de suspendre temporairement ou définitivement le programme de parrainage à tout
        moment sans préavis si des abus sont détectés ou pour des raisons techniques et commerciales.</span></li>
      </ul>
      <h2>6. Attribution des Récompenses</h2>
      <p><span>Les récompenses sont attribuées automatiquement sous un délai de [ex  48 heures] après validation du premier
      paiement du filleul. En cas de retard ou dʼerreur, lʼutilisateur peut contacter le support de NEMO.</span></p>
      <h2>7. Modification et Fin du Programme</h2>
      <p><span>NEMO se réserve le droit de modifier ou mettre fin au programme de parrainage à tout moment. Toute modification
majeure des conditions sera notifiée aux utilisateurs via lʼapplication ou par email. En cas dʼarrêt du programme, les
récompenses obtenues avant la date de suppression resteront valides selon les conditions en vigueur.Conditions Générales du Programme de Parrainage</span></p>
      <h2>8. Contact et Support</h2>
      <p><span>Pour toute question ou réclamation concernant le programme de parrainage, les utilisateurs peuvent contacter le
      support via lʼadresse suivante : hello@nemoapp.fr.</span></p>
      <h3>BESOIN DʼAIDE ?</h3>
      <span>Pour toute autre question, consultez notre <b><u style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.FAQ())}>centre dʼaide</u></b> ou contacter nous via WhatsApp, le {" "}
      <b>
            <u
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("https://tally.so/r/woKoON", "_blank");
              }}
            >
              formulaire de support
            </u>
          </b> ou encore à lʼadresse <a
            style={{
              color: "#636360",
              fontWeight: "700",
              fontSize: "13px",
              textDecoration: "underline",
            }}
            href="mailto:hello@nemoapp.fr"
          >
            hello@nemoapp.fr
          </a>.</span>
    </div>
  );
};

const MentoringLegalsPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <NavigateBeforeIcon style={{ stroke: "#8E8E8E" }} />
      </BackButton>
      <Mentoring_legals_html />
    </Container>
  );
};

export default MentoringLegalsPage;
