import styled from "styled-components";
import { ReactComponent as Mascot404 } from "../../../assets/illustrations/mascotte-404.svg";
import { logout } from "../../../_services/api/auth";
import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "../../_ui/form";
import FileInput from "../../_ui/form/file-input";
import Button from "../../_ui/button";
import { attachementsValidationSchema } from "../signup/validation-schema";
import { useAuthContext } from "../../../_contexts/auth";
import { useNewUserRequest } from "../../../_hooks/user-request/use-user-requests";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100svh;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Padding = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

const DisabledPage = () => {
  return (
    <Container>
      <Mascot404 />
      <Padding>
        <AttachementsForm />
      </Padding>
    </Container>
  );
};

const AttachementsForm = () => {
//   const { updateUser, isLoading, isError, error } = useUpdateUser();
  const { newUserRequest, isLoading } = useNewUserRequest();
  const { user } = useAuthContext();
  const [success, setSuccess] = useState(false);

  const {
    setFieldValue,
    errors,
    handleSubmit,
    values,
    isValid,
    touched,
  } = useFormik({
    initialValues: {
      user_id: user.id,
      category: "professional",
      school_country: "",
      school_name: "",
      school_year: 1,
      attachments: [],
    },
    onSubmit: async () => {
      await newUserRequest(values);
      setSuccess(true);
    },
    validationSchema: attachementsValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  if (success) {
    return (
      <>
        <p>
          Le transfert de document(s) à bien été effectué, vous pouvez vous
          déconnecter et attendre le mail de confirmation.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 0,
            gap: 16,
          }}
        >
          <Button onClick={async () => await logout()}>Se déconnecter</Button>
        </div>
      </>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 25,
        }}
      >
        <h1>Votre compte est désactivé</h1>
        <p>
          Tu peux joindre un nouveau justificatif CPS pour qu'on puisse
          réactiver ton compte
        </p>
      </div>
      <Form>
        <FileInput
          name="attachments[]"
          required
          accept="image/jpeg,image/png,application/pdf"
          onChange={(value) => setFieldValue("attachments[0]", value)}
          // onBlur={handleBlur}
          value={values.attachments?.[0]}
          hasError={touched.attachments?.[0] && !!errors.attachments?.[0]}
          errorMessage={errors?.attachments?.[0] as string}
          label={"Charger mon justificatif CPS"}
          maxFileSize={10 * 1024 * 1024}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 0,
            gap: 16,
          }}
        >
          <Button disabled={!isValid || isLoading} onClick={handleSubmit}>
            Envoyer
          </Button>
          <Button onClick={async () => await logout()}>Se déconnecter</Button>
        </div>
      </Form>
    </div>
  );
};

export default DisabledPage;
