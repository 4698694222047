import {PageContainer} from '../../_ui/page';
import Button from '../../_ui/button';
import {Form} from '../../_ui/form';
import styled from 'styled-components';
import {ROUTES} from '../../../router/routes';
import InfoMessage from '../../_ui/info-message';
import {ReactComponent as MascottePrimary} from '../../../assets/illustrations/mascotte-primary.svg';
import {ReactComponent as MascotteRed} from '../../../assets/illustrations/mascotte-red.svg';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useConfirmUser} from '../../../_hooks/auth/use-confirm-user';
import {useEffect} from 'react';

const LoginPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-height: 100svh;
  height: 100svh;
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
  overflow: auto;
`;

const SignupConfirmPage = () => {
  let [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {isSuccess, confirmUser, isLoading} = useConfirmUser();

  useEffect(() => {
    const confirmationToken = searchParams.get('confirmation_token');
    if (confirmationToken) {
      (async () => {
        await confirmUser({confirmation_token: confirmationToken});
      })();
    }
  }, []);

  return (
    <LoginPageContainer>
      <div
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 100}}
      >
        <InfoMessage
          Illustration={(isSuccess || isLoading) ? <MascottePrimary/> : <MascotteRed/>}
          Title={(isSuccess || isLoading) ? <h1>Let’s go !</h1> : <h1>Oups...</h1>}
          Text={
            isLoading
              ? <p>Vérification du lien</p>
              : isSuccess
                ? <p>Ton compte a été validé avec succès. Tu peux maintenant te connecter à ton compte.</p>
                : <p>Lien invalide ou expiré.</p>
          }
        />
        <Form>
          <Button
            onClick={() => navigate(ROUTES.LOGIN())}
          >
            Connexion
          </Button>
        </Form>
      </div>
    </LoginPageContainer>
  );
};

export default SignupConfirmPage;