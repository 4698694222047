import styled from "styled-components";
import {ReactComponent as MascotPrimary} from '../../../assets/illustrations/mascotte-primary.svg';

const Container = styled.div`
  display: flex;
  width: 100svw;
  height: 100svh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1D1D1B;
`;

const Label = styled.p`
  color: var(--Text-Reverse, #FFF);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  /* Mobile/Body */
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;

const LoadingScreen = ({title}:{title?: string}) => {
  return(
    <Container>
      <MascotPrimary />
      <Label>{title || 'Chargement en cours ...'}</Label>
      <div className="loader">
        <div className="loaderBar"></div>
      </div>
    </Container>
  )
}

export default LoadingScreen;