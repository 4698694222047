import {PageContainer} from '../../../_ui/page';
import TabbedNavigation from '../../../_ui/tabbed-navigation';
import {useState} from 'react';
import {useDeletePathologyDetail, usePathologyDetails} from '../../../../_hooks/pathology-detail/use-pathology-details';
import {APIListPayload, APISortingObject} from '../../../../_services/api/_helpers/api-list-types';
import PathologyDetailForm from './PathologyDetailForm';
import {ReactComponent as TrashIcon} from '../../../../_icons/trash.svg';
import {useTheme} from '@table-library/react-table-library/theme';
import {getTheme} from '@table-library/react-table-library/baseline';
import * as React from 'react';
import {CompactTable} from '@table-library/react-table-library/compact';
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import {useTranslation} from 'react-i18next';
import {PathologyDetailCategoryCaption} from '../_commons/captions';
import {APIPathologyDetail} from '../../../../_services/api/_helpers/api-types';
import Modal from '../../../_ui/modal';
import Button from '../../../_ui/button';
import { debounce } from "lodash";
import { TextField } from "../../../_ui/form";
import PaginationDisplay from "../../../_ui/pagination";

const DeleteCell = ({onDelete}) => {
  return (
    <div>
      <TrashIcon
        color={"#FF453A"}
        onClick={() => onDelete?.()}
        height={"100%"}
      />
    </div>
  );
};

const PathologyDetailsTable = ({payload, readonly = false, onDelete} : {payload?: APIListPayload, readonly?: boolean, onDelete?: any}) => {
  const [selectedPathologyDetail, setSelectedPathologyDetail] = useState<Partial<APIPathologyDetail> | null>(null);
  const {t} = useTranslation();
  const {deletePathologyDetail} = useDeletePathologyDetail();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<APISortingObject>([
    {
      name: "id",
      direction: "desc",
    },
  ]);
  const [search, setSearch] = React.useState("");

  const { getPathologyDetails, pathology_details, isLoading } = usePathologyDetails({
    page_size: 20,
    page_number: currentPage,
    filters: { ...payload.filters, search: [search] },
    sorting: sorting,
  });

  const data = {nodes: pathology_details?.list || [], pageInfo:{totalPages:pathology_details&&(Math.ceil(pathology_details.total_count/pathology_details.page_size)||0)}};

  // -------- Pagination / Sorting / Search

  const pagination = usePagination(
    data,
    {
      state: {
        page: 0,
        size: 20
      },
      onChange: onPaginationChange,
    },
    { isServer: true }
  );

  function onPaginationChange(action, state) {
    // refresh page with payload page value = action.payload.page +1
    setCurrentPage(action.payload.page + 1);
  }

  const handleSearch = (event) => {
    // refresh page with input search value = event.target.value
    setSearch(event.target.value);
  };

  const onInputChange = React.useMemo(() => debounce(handleSearch, 1000), []);

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {},
      isServer: true,
    }
  );

  function onSortChange(action, state) {
    // refresh page with search state value = state.sortKey / state.reverse
    setSorting([
      {
        name: state.sortKey,
        direction: state.reverse ? "desc" : "asc",
      },
    ]);
  }

  // -- End Pagination / Sorting / Search

  const handleCellClick = setSelectedPathologyDetail;

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 70px minmax(120px, 175px) minmax(100px, 1fr) minmax(100px, 100px) 120px 50px;
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  const COLUMNS = [
    {
      label: 'ID',
      renderCell: (item) => item.id.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      },
      sort: { sortKey: "id" },
    },
    {
      label: 'Catégorie',
      renderCell: (item) => (
        <PathologyDetailCategoryCaption
          className={item.category}
        >
          {t(`enums.pathology_detail.category.${item.category}`)}
        </PathologyDetailCategoryCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      },
      sort: { sortKey: "category" },
    },
    {
      label: 'Label',
      renderCell: (item) => <span title={item.text}>{item.text}</span>,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      },
      sort: { sortKey: "text" },
    },
    {
      label: 'Pop-over',
      renderCell: (item) => <span title={item.popover}>{item.popover}</span>,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      },
      sort: { sortKey: "popover" },
    },
    {
      label: 'Date de création',
      renderCell: (item) => new Date(item.created_at).toLocaleDateString('fr-fr'),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      },
      sort: { sortKey: "created_at" },
    },
    {
      label: '',
      renderCell: (item) => (
        <DeleteCell
          onDelete={async () => {
            if (item.id && window.confirm("Voulez-vous vraiment supprimer cet élément?")) {
              if (onDelete) {
                onDelete(item)
              } else {
                await deletePathologyDetail(item.id);
              }
              setTimeout(() => getPathologyDetails(), 200)
            }
          }}
        />
      ),
    },
  ];

  return (
    <>
      {!readonly && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => setSelectedPathologyDetail({})}
          >
            Ajouter
          </Button>
        </div>
      )}
      {selectedPathologyDetail && (
        // @ts-ignore
        <Modal
          withPadding
          isOpen={!!selectedPathologyDetail}
          onRequestClose={() => setSelectedPathologyDetail(null)}
          overlay={true}
          withWrapper={true}
        >
          <h2>{selectedPathologyDetail.id ? `Modification du pathology helper #${selectedPathologyDetail.id}` : 'Création d\'un pathology helper'}</h2>
          <PathologyDetailForm
            pathologyDetail={selectedPathologyDetail}
            onChange={() => {
              setTimeout(getPathologyDetails, 200);
              setSelectedPathologyDetail(null);
            }}
          />
        </Modal>
      )}
      <TextField
        type="text"
        label="Rechercher une pathologie helpers"
        placeholder={""}
        onChange={(e) => {
          onInputChange(e);
        }}
      />
      <br />
      {isLoading ? (
        <span className="spin-loader"></span>
      ) : (
        <div style={{ textAlign: "left" }}>
          <CompactTable
            columns={COLUMNS}
            data={data}
            theme={theme}
            sort={sort}
            pagination={pagination}
            layout={{
              custom: true,
              horizontalScroll: true,
              fixedHeader: true,
            }}
          />
        </div>
      )}
      <br />
      <PaginationDisplay pagination={pagination} data={data}/>
    </>
  );
};

const PathologyDetailsPage = () => {
  const [activeTab, setActiveTab] = useState<string>();
  const {t} = useTranslation();

  const items = Object.keys(t('enums.pathology_detail.category')).map((category) => (
    {
      key: category,
      element: <span>{t(`enums.pathology_detail.category.${category}`)}</span>,
      onClick: () => setActiveTab(category),
    }
  ));

  const categoryKey = activeTab || items[0]?.key;

  return (
    <PageContainer
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <TabbedNavigation
        items={items}
        defaultActive={items.findIndex((item) => item.key === categoryKey)}
      />
      {categoryKey && (
        <PathologyDetailsTable
          payload={{filters: {category: [categoryKey]}}}
        />
      )}
    </PageContainer>
  );
};

export default PathologyDetailsPage;

export {
  PathologyDetailsTable,
};