import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {ROUTES} from './routes';
import LoginPage from '../components/pages/login';
import AuthProvider, {useAuthContext} from '../_contexts/auth';
import {QueryClient, QueryClientProvider} from 'react-query';
import AdminLayout from '../components/_ui/layout/admin';
import PathologyDetailsPage from '../components/pages/admin/pathology_details';
import PathologiesPage from '../components/pages/admin/pathologies';
import PathologyPage from '../components/pages/admin/pathology';
import SearchPage from '../components/pages/admin/search';
import HomePage from '../components/pages/home';
import PublicLayout from '../components/_ui/layout/public';
import SignupPage from '../components/pages/signup';
import ResetPasswordPage from '../components/pages/login/reset_password';
import UsersPage from '../components/pages/admin/users';
import * as Dashboard from '../components/pages/dashboard';
import DashboardLayout from '../components/_ui/layout/dashboard';
import {Fragment} from 'react';
// import EscapeFromRouter from './EscapeFromRouter';
import UserPage from '../components/pages/admin/user';
import BalanceTransactionsPage from '../components/pages/admin/balance_transactions';
import UserRequestsPage from '../components/pages/admin/user_requests';
import UserRequestPage from '../components/pages/admin/user_request';
import SignupConfirmPage from '../components/pages/signup_confirm';
import QuotesPage from '../components/pages/admin/quotes';
import NotFoundPage from '../components/pages/404';
import Legals from '../components/pages/legals';
import ErrorPage from '../components/pages/500';
import ProUserRequestsPage from '../components/pages/admin/pro_user_requests';
import ProUserRequestPage from '../components/pages/admin/pro_user_request';
import DisabledPage from '../components/pages/disabled';
import Mentoring from '../components/pages/dashboard/mentoring';
import MentoringPage from '../components/pages/dashboard/mentoring';
import FAQPage from '../components/pages/dashboard/faq';
import MentoringLegalsPage from '../components/pages/dashboard/mentoring-legals';

const queryClient = new QueryClient();

const menuList = (user) => {
  if (!user?.id) {
    return [];
  }
  const {role} = user;

  let array = [];

  if (role === 'admin') {
    array.push(
      {
        label: 'Pathologies',
        link: ROUTES.PATHOLOGIES(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Pathologies helpers',
        link: ROUTES.PATHOLOGY_DETAILS(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Utilisateurs',
        link: ROUTES.USERS(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Demandes Étudiantes',
        link: ROUTES.USER_REQUESTS(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Demandes Pros',
        link: ROUTES.PRO_USER_REQUESTS(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Transactions',
        link: ROUTES.BALANCE_TRANSACTIONS(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Citations',
        link: ROUTES.QUOTES(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Stripe',
        link: 'https://dashboard.stripe.com/',
        target:"_blank",
        external: true,
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Brevo',
        link: 'https://app.brevo.com/',
        target:"_blank",
        external: true,
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
    array.push(
      {
        label: 'Clarity',
        link: 'https://clarity.microsoft.com/projects',
        target:"_blank",
        external: true,
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      }
    );
  }

  return (
    [
      {
        label: 'Accueil',
        link: ROUTES.DASHBOARD(),
        exactPath: true,
        Icon: () => {},
        onClick: () => {}
      },
      // {
      //   label: 'Mon compte',
      //   link: ROUTES.USERS(user.id),
      //   exactPath: true,
      //   Icon: () => {},
      //   onClick: () => {}
      // },
      ...array,
    ]
  );
};

const dashboardLayoutAssociations = [
  {
    path: ROUTES.PROFILES('me'),
    Component: Dashboard.ProfilePage,
    Layout: DashboardLayout,
  },
  {
    path: ROUTES.USERS(':id'),
    Component: Dashboard.UserPage,
    Layout: DashboardLayout,
  },
  {
    path: ROUTES.DASHBOARD(),
    Component: Dashboard.HomePage,
    Layout: DashboardLayout,
  },
  {
    path: ROUTES.DIAGNOSTICS(':id'),
    Component: Dashboard.DiagnosticPage,
    Layout: Fragment,
  },
  {
    path: ROUTES.DIAGNOSTICS(),
    Component: Dashboard.DiagnosticsPage,
    Layout: DashboardLayout,
  },
  {
    path: ROUTES.DIAGNOSTIC_PATHOLOGY_DETAILS(':id'),
    Component: Dashboard.DiagnosticPathologyDetailsPage,
    Layout: Fragment,
  },
  {
    path: ROUTES.DIAGNOSTIC_RESULT(':id'),
    Component: Dashboard.DiagnosticResultPage,
    Layout: Fragment,
  },
  {
    path: ROUTES.LEGALS(),
    Component: Legals,
    Layout: PublicLayout,
  },
  {
    path: ROUTES.MENTORING(),
    Component: MentoringPage,
    Layout: PublicLayout,
  },
  {
    path: ROUTES.MENTORING_LEGALS(),
    Component: MentoringLegalsPage,
    Layout: PublicLayout,
  },
  {
    path: ROUTES.FAQ(),
    Component: FAQPage,
    Layout: PublicLayout,
  }
];

const DashboardRoutes = () => {
  const {user} = useAuthContext();

  if (user.role === 'admin') {
    return (
      <AdminLayout items={menuList(user)}>
        <Routes>
          <Route path={ROUTES.DASHBOARD()} Component={SearchPage}/>
          <Route path={ROUTES.PATHOLOGIES(':id')} Component={PathologyPage}/>
          <Route path={ROUTES.PATHOLOGIES()} Component={PathologiesPage}/>
          <Route path={ROUTES.PATHOLOGY_DETAILS()} Component={PathologyDetailsPage}/>
          <Route path={ROUTES.USERS(':id')} Component={UserPage}/>
          <Route path={ROUTES.USERS()} Component={UsersPage}/>
          <Route path={ROUTES.BALANCE_TRANSACTIONS()} Component={BalanceTransactionsPage}/>
          <Route path={ROUTES.USER_REQUESTS(':id')} Component={UserRequestPage}/>
          <Route path={ROUTES.USER_REQUESTS()} Component={UserRequestsPage}/>
          <Route path={ROUTES.PRO_USER_REQUESTS()} Component={ProUserRequestsPage}/>
          <Route path={ROUTES.PRO_USER_REQUESTS(':id')} Component={ProUserRequestPage}/>
          {/*<Route path={ROUTES.QUOTES(':id')} Component={QuotesPage}/>*/}
          <Route path={ROUTES.QUOTES()} Component={QuotesPage}/>
          <Route path={ROUTES.NOTFOUND()} element={<NotFoundPage redirect={ROUTES.DASHBOARD()}></NotFoundPage>}/>
          <Route path={ROUTES.ERROR()} element={<ErrorPage redirect={ROUTES.DASHBOARD()}></ErrorPage>}/>
          <Route path='*' element={<Navigate to={ROUTES.NOTFOUND()}/>} />
          <Route path={ROUTES.HOME()} Component={HomePage}/>
        </Routes>
      </AdminLayout>
    );
  }

  return (
    <Routes>
      {
        dashboardLayoutAssociations.map((dashboardLayoutAssociation, index) => (
          <Route
            key={index}
            path={dashboardLayoutAssociation.path}
            Component={(props) => (
              <dashboardLayoutAssociation.Layout>
                <dashboardLayoutAssociation.Component {...props}/>
              </dashboardLayoutAssociation.Layout>
            )}
          />
        ))
      }
      <Route path={ROUTES.NOTFOUND()} element={<NotFoundPage redirect={ROUTES.DASHBOARD()}></NotFoundPage>}/>
      <Route path={ROUTES.ERROR()} element={<ErrorPage redirect={ROUTES.DASHBOARD()}></ErrorPage>}/>
      <Route path={ROUTES.HOME()} Component={HomePage}/>
      <Route path='*' element={<Navigate to={ROUTES.NOTFOUND()}/>} />
      {/* <Route path="*" element={<EscapeFromRouter />}/> */}
    </Routes>
  );
};

const PublicRoutes = () => (
  <PublicLayout>
    <Routes>
      <Route path={ROUTES.RESET_PASSWORD()} Component={ResetPasswordPage}/>
      <Route path={ROUTES.SIGNUP_CONFIRM()} Component={SignupConfirmPage}/>
      <Route path={ROUTES.LOGIN()} Component={LoginPage}/>
      <Route path={ROUTES.SIGNUP()} Component={SignupPage}/>
      <Route path={ROUTES.HOME()} Component={HomePage}/>
      <Route path={ROUTES.LEGALS()} Component={Legals}/>
      <Route path={ROUTES.NOTFOUND()} element={<NotFoundPage redirect={ROUTES.HOME()}></NotFoundPage>}/>
      <Route path={ROUTES.ERROR()} element={<ErrorPage redirect={ROUTES.HOME()}></ErrorPage>}/>
      <Route path='*' element={<Navigate to={ROUTES.NOTFOUND()}/>} />
    </Routes>
  </PublicLayout>
);

const DisabledRoutes = () => (
  <PublicLayout>
    <Routes>
      <Route index path={ROUTES.DISABLED()} Component={DisabledPage}/>
      <Route path='*' element={<Navigate to={ROUTES.DISABLED()}/>} />
    </Routes>
  </PublicLayout>
);

const ApplicationRoutes = () => {
  const {user} = useAuthContext();
  if(user){
    if (user.disabled){
      return <DisabledRoutes/>
    } else {
      return <DashboardRoutes/>
    }
  } else {
    return <PublicRoutes/>
  }
};


const NemoRouter = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <AuthProvider>
          <ApplicationRoutes/>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default NemoRouter;