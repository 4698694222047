import { PageContainer } from "../../_ui/page";
import Button from "../../_ui/button";
import { CodeInput, Form, TextField } from "../../_ui/form";
import styled from "styled-components";
import { useFormik } from "formik";
import { INITIAL_FORM_VALUES } from "./config";
import {
  nameFormValidationSchema,
  professionalNumberFormValidationSchema,
  studentSchoolFormValidationSchema,
  studentMailAndDocumentsFormValidationSchema,
  sponsorFormValidationSchema,
  studentSchoolYearFormValidationSchema,
  credentialsFormValidationSchema,
} from "./validation-schema";
import {
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import CheckBox from "../../_ui/form/check-box";
import BulletPoints from "../../_ui/bullet-points";
import { useEffect, useState } from "react";
import { getProfessionals } from "../../../_services/api/professional";
import InfoMessage from "../../_ui/info-message";
import { ReactComponent as MascottePrimary } from "../../../assets/illustrations/mascotte-primary.svg";
import { useCreateUserRequest, useNewUserRequest } from "../../../_hooks/user-request/use-user-requests";
import FileInput from "../../_ui/form/file-input";
import * as React from "react";
import { APIProfessional } from "../../../_services/api/_helpers/api-types";
import CGUModal from "../../_ui/cgu-modal";
import { useSignup } from "../../../_hooks/auth/use-signup";
import { AxiosResponse } from "axios";

const LoginPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-height: 100svh;
  height: 100svh;
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
`;

const NameForm = ({ initialUser, onSubmit }) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...INITIAL_FORM_VALUES,
      ...initialUser,
    },
    // onSubmit: (formValues) => getProfessionals({
    //   filters: {
    //     first_name: [formValues.first_name],
    //     last_name: [formValues.last_name],
    //   }
    // }).then((res) => onSubmit(res.list)),
    onSubmit: (formValues) => onSubmit(formValues),
    validationSchema: nameFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h1>Inscription</h1>
        <p>
          C’est parti ! Dis moi comment tu t’appelles, je vais te chercher dans
          la base de donnée kiné
        </p>
      </div>
      <Form>
        <TextField
          name="first_name"
          required
          placeholder={"Prénom"}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.first_name}
          hasError={touched.first_name && !!errors.first_name}
          errorMessage={errors.first_name?.toString()}
        />
        <TextField
          name="last_name"
          required
          placeholder={"Nom"}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.last_name}
          hasError={touched.last_name && !!errors.last_name}
          errorMessage={errors.last_name?.toString()}
        />
        <TextField
          type="date"
          name="birth_date"
          required
          placeholder={"Date de naissance"}
          label={"Date de naissance"}
          smallVerticalPadding
          // onFocus={(event) => {
          //   event.target.type = 'date';
          //   setTimeout(() => event.target.click(), 150);
          // }}
          onChange={handleChange}
          onBlur={(event) => {
            handleBlur(event);
            setTimeout(() => event.target.blur(), 150);
          }}
          value={values.birth_date || ""}
          hasError={touched.birth_date && !!errors.birth_date}
          errorMessage={"Vous devez avoir au moins 16 ans pour vous inscrire."}
        />
        <CheckBox
          name="student"
          label={"Coche cette case si tu es étudiant"}
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.student}
          hasError={touched.student && !!errors.student}
          errorMessage={errors.student?.toString()}
        />
        <BulletPoints number={5} activeIndex={0} />
        <Button disabled={!isValid} onClick={handleSubmit}>
          Suivant
        </Button>
      </Form>
    </div>
  );
};
const ProfessionalNumberForm = ({ onSubmit, back }) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: { professional_number: "" },
    onSubmit: (formValues) =>
      getProfessionals({
        page_size: 20,
        filters: {
          professional_number: [formValues.professional_number],
        },
      }).then((res) => onSubmit(res.list)),
    validationSchema: professionalNumberFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "90%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 50,
        }}
      >
        <h1>Oups...</h1>
        <p>Je vais avoir besoin d’un petit coup de pouce</p>
      </div>
      <Form>
        <TextField
          name="professional_number"
          required
          placeholder={"N° RPPS"}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.professional_number}
          hasError={touched.professional_number && !!errors.professional_number}
          errorMessage={errors.professional_number}
        />
        <BulletPoints number={5} activeIndex={1} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: 16,
          }}
        >
          <Button
            style={{ flex: 1 }}
            $display="secondary"
            onClick={() => back()}
          >
            Retour
          </Button>
          <Button
            style={{ flex: 1 }}
            disabled={!isValid}
            onClick={handleSubmit}
          >
            Suivant
          </Button>
        </div>
      </Form>
    </div>
  );
};

const SponsorForm = ({ onSubmit, initialUser }) => {
  const { handleSubmit, values, isValid, setFieldValue } = useFormik({
    initialValues: {
      sponsor_code: "",
      ...initialUser,
    },
    onSubmit: (formValues) => onSubmit(formValues),
    validationSchema: sponsorFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 25,
        }}
      >
        <p>As-tu un code de parrainage&nbsp;?</p>
      </div>
      <Form>
        <CodeInput
          name="sponsor_code"
          onChange={(value) => setFieldValue("sponsor_code", value)}
          value={values.sponsor_code}
        />
        <BulletPoints number={5} activeIndex={2} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: 16,
          }}
        >
          <Button
            style={{ flex: 1 }}
            $display="secondary"
            onClick={handleSubmit}
          >
            Passer
          </Button>
          <Button
            style={{ flex: 1 }}
            disabled={!isValid}
            onClick={handleSubmit}
          >
            Suivant
          </Button>
        </div>
      </Form>
    </div>
  );
};

const CredentialsForm = ({ user = {}, onSuccess }) => {
  const [openModal, setOpenModal] = useState<string | null>(null);
  const { signup, isLoading, data } = useSignup();
  const { newUserRequest, isLoading:isLoading2 } = useNewUserRequest();
  const [success, setSuccess] = useState(false)
  
  const {
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...user,
      // email: (user as APIProfessional).email || '',
      email: "",
      password: "",
      terms_of_use_accepted_at: "",
      attachments: [],
    },
    onSubmit: (formValues) => signup(formValues),
    validationSchema: credentialsFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  // when receive info of user created, then attach his user_request
  useEffect(()=> {
    if (data) {
      const resp = data as unknown as AxiosResponse
      if (resp.data.user.id) {
        sendAttachments(resp.data.user.id)
      }
    }
  }, [data])

  useEffect(() => {
    if (success) {
      onSuccess(values);
    }
  }, [success]);

  async function sendAttachments (user_id) {
    await newUserRequest({
      user_id: user_id,
      category: "professional",
      school_country: "",
      school_name: "",
      school_year: 1,
      attachments: values.attachments,
    });
    setSuccess(true)
   }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 25,
        }}
      >
        <h1>Inscription</h1>
        <p>
          Entre une adresse de contact, un mot de passe et un justificatif CPS pour confirmer ton
          inscription
        </p>
      </div>
      <Form>
        <TextField
          type="email"
          name="email"
          required
          placeholder={"Veuillez renseigner une adresse email."}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          hasError={touched.email && !!errors.email}
          errorMessage={errors.email}
        />
        <TextField
          type="password"
          name="password"
          required
          placeholder={"Veuillez renseigner un mot de passe."}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          hasError={touched.password && !!errors.password}
          errorMessage={errors.password}
        />
        <FileInput
          name="attachments[]"
          required
          accept="image/jpeg,image/png,application/pdf"
          onChange={(value) => setFieldValue("attachments[0]", value)}
          // onBlur={handleBlur}
          value={values.attachments?.[0]}
          hasError={touched.attachments?.[0] && !!errors.attachments?.[0]}
          errorMessage={errors?.attachments?.[0] as string}
          label={"Charger mon justificatif CPS"}
          maxFileSize={10 * 1024 * 1024}
        />
        <div style={{ display: "flex", justifyItems:  "center", alignItems: "center" }}>
          <CheckBox
            required
            name="term_of_use"
            // label={"J’accepte les conditions d’utilisation"}
            onChange={(event) =>
              setFieldValue(
                "terms_of_use_accepted_at",
                event.target.checked ? new Date().toISOString() : ""
              )
            }
            onBlur={handleBlur}
            hasError={
              touched.terms_of_use_accepted_at &&
              !!errors.terms_of_use_accepted_at
            }
            errorMessage={errors.terms_of_use_accepted_at}
          />
          <div style={{marginLeft:"1rem", cursor:"pointer"}} onClick={() => setOpenModal("CGUModal")}>
            <span style={{textDecoration: "underline"}}>J’accepte les conditions d’utilisation</span>
          </div>
        </div>
        <BulletPoints number={5} activeIndex={3} />
        <Button disabled={!isValid || isLoading || isLoading2} onClick={handleSubmit}>
          Envoyer
        </Button>
      </Form>
      <CGUModal isOpen={openModal} onClose={() => setOpenModal(null)}/>
    </div>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  > button {
    width: 100%;
  }
`;

const StudentSchoolForm = ({ user = {}, onSubmit, back }) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...user,
      school_name: "",
      school_country: "",
    },
    onSubmit: (formValues) => onSubmit(formValues),
    validationSchema: studentSchoolFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 25,
        }}
      >
        <h1>Tu es étudiant ?</h1>
        <p>
          C’est super ça ! Tu verras NEMO est une super application pour
          apprendre la kiné
        </p>
      </div>
      <Form>
        <TextField
          type="text"
          name="school_name"
          required
          placeholder={"Nom de ton école"}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.school_name}
          hasError={touched.school_name && !!errors.school_name}
          errorMessage={errors.school_name}
        />
        <TextField
          type="text"
          name="school_country"
          required
          placeholder={"Pays de ton école"}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.school_country}
          hasError={touched.school_country && !!errors.school_country}
          errorMessage={errors.school_country}
        />
        <BulletPoints number={4} activeIndex={1} />
        <ButtonsContainer>
          <Button $display="secondary" onClick={back}>
            Précédent
          </Button>
          <Button disabled={!isValid} onClick={handleSubmit}>
            Suivant
          </Button>
        </ButtonsContainer>
      </Form>
    </div>
  );
};

const Label = styled.label<{ $checked?: boolean }>`
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #ebebe8;
  background: ${(props) => (props.$checked ? "#1D1D1B" : "none")};
  color: ${(props) => (props.$checked ? "#FFFFFF" : "#1D1D1B")};
  font-size: 24px;
`;

const StudentYearForm = ({ user = {}, onSubmit, back }) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...user,
      school_year: "",
    },
    onSubmit: (formValues) => onSubmit(formValues),
    validationSchema: studentSchoolYearFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 25,
        }}
      >
        <p>En quelle année es-tu ?</p>
      </div>
      <Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {[...Array(5)].map((_, index) => (
            <Label
              key={index}
              $checked={index + 1 === +values.school_year}
              htmlFor={`school_year-${index + 1}`}
            >
              <input
                type="radio"
                name="school_year"
                id={`school_year-${index + 1}`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={index + 1}
                checked={index + 1 === +values.school_year}
                style={{ appearance: "none", margin: 0 }}
              />
              {index + 1}
            </Label>
          ))}
        </div>
        <BulletPoints number={4} activeIndex={2} />
        <ButtonsContainer>
          <Button $display="secondary" onClick={back}>
            Précédent
          </Button>
          <Button disabled={!isValid} onClick={handleSubmit}>
            Suivant
          </Button>
        </ButtonsContainer>
      </Form>
    </div>
  );
};

const StudentMailAndDocumentsForm = ({ user = {}, onSubmit, back }) => {
  const { createUserRequest, isLoading, isError, error } =
    useCreateUserRequest();
  const {
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...user,
      email: "",
      attachments: [],
    },
    onSubmit: async (formValues) => {
      // @ts-ignore
      const { first_name, last_name, email, birth_date, ...userRequest } =
        formValues;
      await createUserRequest({
        ...userRequest,
        category: "student",
        user: { first_name, last_name, email, birth_date },
      });
      onSubmit(formValues);
    },
    validationSchema: studentMailAndDocumentsFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: 25,
        }}
      >
        <h1>C’est bientôt fini !</h1>
        <p>
          Pour valider ton inscription, envoie ton attestation de formation.
        </p>
      </div>
      <Form>
        <TextField
          type="email"
          name="email"
          required
          placeholder={"Veuillez renseigner une adresse email."}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          hasError={touched.email && !!errors.email}
          errorMessage={errors.email}
        />
        <FileInput
          name="attachments[]"
          required
          accept="image/jpeg,image/png,application/pdf"
          onChange={(value) => setFieldValue("attachments[0]", value)}
          // onBlur={handleBlur}
          value={values.attachments?.[0]}
          hasError={touched.attachments?.[0] && !!errors.attachments?.[0]}
          errorMessage={errors?.attachments?.[0] as string}
          label={"Charger mon justificatif"}
          maxFileSize={10 * 1024 * 1024}
        />
        <BulletPoints number={4} activeIndex={3} />
        <ButtonsContainer>
          <Button $display="secondary" onClick={back}>
            Précédent
          </Button>
          <Button disabled={!isValid || isLoading} onClick={handleSubmit}>
            Envoyer
          </Button>
        </ButtonsContainer>
      </Form>
    </div>
  );
};
const StudentOnboardingEnd = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: 100,
      }}
    >
      <InfoMessage
        Illustration={<MascottePrimary />}
        Title={<h1>Let’s go !</h1>}
        Text={
          <p>
            Nous reviendrons vers toi dès que nous aurons vérifié tes
            informations.
            <br />
            <br />
            <b>Pense à vérifier ta boite mail !</b>
          </p>
        }
      />
      <Form>
        <Button onClick={() => navigate(ROUTES.HOME())}>
          Revenir à l’accueil
        </Button>
      </Form>
    </div>
  );
};

const StudentForm = ({ initialUser, back }) => {
  const [user, setUser] = useState(initialUser);
  const [currentStep, setCurrentStep] = useState(0);

  const handleSubmit = (values) => {
    setUser(values);
    setCurrentStep((prev) => prev + 1);
  };

  const Steps = [
    () => <StudentSchoolForm user={user} onSubmit={handleSubmit} back={back} />,
    () => (
      <StudentYearForm
        user={user}
        onSubmit={handleSubmit}
        back={() => setCurrentStep((prev) => prev - 1)}
      />
    ),
    () => (
      <StudentMailAndDocumentsForm
        user={user}
        onSubmit={handleSubmit}
        back={() => setCurrentStep((prev) => prev - 1)}
      />
    ),
    StudentOnboardingEnd,
  ];

  const CurrentStep = Steps[currentStep];

  return <CurrentStep />;
};

const ResultContainer = styled.div<{ $selected?: boolean }>`
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border-radius: var(--M, 16px);
  border: 1px solid #ebebe8;
  box-sizing: border-box;

  ${(props) =>
    props.$selected &&
    `
    border: 2px solid #ADE82F;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  `}
`;

const SubTitle = styled.span`
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.2px;
`;

const NotMe = styled.span`
  color: var(--Text-primary, #1d1d1b);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const RPPS = styled.span`
  color: var(--Text-primary, #1d1d1b);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;

const AddressLine = styled.div`
  color: #636360;
  font-size: 12px;
  font-style: italic;
`;

const Address = ({ professional }: { professional: APIProfessional }) => {
  const {
    address_street_number,
    address_street_number_rep,
    address_street,
    address_postal_code,
    address_city,
    address_country,
  } = professional;
  return (
    <div>
      <AddressLine>
        {address_street_number}
        {address_street_number_rep} {address_street}
      </AddressLine>
      <AddressLine>
        {address_postal_code} {address_city}
      </AddressLine>
      {/*<AddressLine>{address_country}</AddressLine>*/}
    </div>
  );
};

const FormContainer = styled.div`
  max-height: ${window.screen.availHeight / 2.5}px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
`;

const RPPSResultsScreen = ({ results, onChange, setResults }) => {
  const isUniq = results.length === 1;
  const manyResults = results.length > 1;
  const [selectedResultId, setSelectedResultId] = useState<number | null>(
    isUniq ? results[0].id : null
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "fill-content",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h1>{manyResults ? "Hum..." : "Super !"}</h1>
        <p>
          {manyResults
            ? "Il semble que vous soyez plusieurs. Il va falloir m’aider un peu."
            : "Je t’ai trouvé, est-ce bien toi ?"}
        </p>
      </div>
      <Form>
        <FormContainer>
          {results.map((result, index) => (
            <ResultContainer
              key={index}
              onClick={() => setSelectedResultId(result.id)}
              $selected={result.id === selectedResultId}
            >
              <div>
                <h3 style={{ margin: 0 }}>
                  {result.first_name} {result.last_name}
                </h3>
                <SubTitle>{result.job}</SubTitle>
              </div>
              <RPPS>RPPS N° : {result.professional_number}</RPPS>
              <Address professional={result} />
            </ResultContainer>
          ))}
        </FormContainer>
        <NotMe onClick={() => setResults([])}>
          {isUniq ? "Ce n’est pas moi" : "Je préfère entrer mon numéro RPPS"}
        </NotMe>
        <BulletPoints number={5} activeIndex={1} />
        <Button
          disabled={!selectedResultId}
          onClick={() =>
            onChange(results.find((r) => r.id === selectedResultId))
          }
        >
          {manyResults ? "Valider" : "Oui, c’est moi !"}
        </Button>
      </Form>
    </div>
  );
};

const OnboardingEnd = () => (
  <div
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginBottom: 25,
      }}
    >
      <MascottePrimary />
      <h1>C’est bientôt fini !</h1>
      <p>
        Pour valider ton inscription, clique sur le bouton 'Valider mon compte'
        dans l'email que tu viens de recevoir.
      </p>
    </div>
    <BulletPoints number={5} activeIndex={4} />
  </div>
);

const SignupPage = () => {
  let [searchParams, _setSearchParams] = useSearchParams();
  const [user, setUser] = useState({
    professional_number: undefined,
    student: false,
    sponsor_code: searchParams.get("sponsor_code") || "",
  });
  const [results, setResults] = useState<object[] | undefined>();
  const [currentStep, setCurrentStep] = useState(0);

  const handleNameChange = (formValues) => {
    setUser(formValues);
    if (!formValues.student) {
      getProfessionals({
        filters: {
          first_name: [formValues.first_name],
          last_name: [formValues.last_name],
        },
      }).then((res) => {
        setResults(res.list);
        setCurrentStep((prev) => prev + 1);
      });
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleUserChange = (data, stepGap = 1) => {
    setUser((prev) => ({ ...prev, ...data }));
    setCurrentStep((prev) => prev + stepGap);
  };

  const handleGoBack = () => {
    setCurrentStep((prev) => prev - 1);
    setResults(undefined);
  };

  const Steps = [
    () => <NameForm initialUser={user} onSubmit={handleNameChange} />,
    () =>
      user.student ? (
        <StudentForm initialUser={user} back={handleGoBack} />
      ) : results.length === 0 ? (
        <ProfessionalNumberForm onSubmit={setResults} back={handleGoBack} />
      ) : (
        <RPPSResultsScreen
          onChange={(data) => handleUserChange(data, user.sponsor_code ? 2 : 1)}
          results={results}
          setResults={setResults}
        />
      ),
    () => <SponsorForm initialUser={user} onSubmit={handleUserChange} />,
    () => (
      <CredentialsForm
        user={user}
        onSuccess={() => setCurrentStep((prev) => prev + 1)}
      />
    ),
    () => <OnboardingEnd />,
  ];

  const CurrentStep = Steps[currentStep];

  return (
    <LoginPageContainer>
      <CurrentStep />
      <div>
        <span>J’ai déjà un compte. </span>
        <Link to={ROUTES.LOGIN()}>Connexion</Link>
      </div>
    </LoginPageContainer>
  );
};

export default SignupPage;
