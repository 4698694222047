import { PageContainer } from "../../../_ui/page";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../../../_hooks/user/use-users";
import * as React from "react";
import styled from "styled-components";
import { breakpoint } from "styled-components-breakpoint";
import { BalanceTransactionsTable } from "../balance_transactions";
import { Form, TextField } from "../../../_ui/form";
import Button from "../../../_ui/button";
import { useFormik } from "formik";
import { useCreateBalanceTransaction } from "../../../../_hooks/balance-transaction/use-balance-transactions";
import { balanceTransactionFormValidationSchema } from "./validation-schema";
import { APIBalanceTransaction } from "../../../../_services/api/_helpers/api-types";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../_ui/modal";
import { STRIPE_ROUTES } from "../../../../_services/stripe/routes";
import { ROUTES } from "../../../../router/routes";
import Caption from "../../../_ui/caption";
import { UserPlanCaption, UserRoleCaption } from "../_commons/captions";
import { useTranslation } from "react-i18next";

const Information = ({ Label, children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      flex: 1,
      gap: 8,
    }}
  >
    <div
      style={{
        display: "flex",
        fontWeight: 600,
        flex: 1,
        width: 150,
        minWidth: 150,
      }}
    >
      {Label}
    </div>
    <div
      style={{
        display: "flex",
        flex: 1,
        minWidth: 180,
      }}
    >
      {children}
    </div>
  </div>
);

const InformationContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${breakpoint("md" as any)`
      width: 45%;
    `}
  }
`;

const BalanceTransactionForm = ({
  initialValues = {},
  onSuccess,
}: {
  initialValues?: Partial<APIBalanceTransaction>;
  onSuccess?: any;
}) => {
  const { createBalanceTransaction, isLoading } = useCreateBalanceTransaction();
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    isSubmitting,
    touched,
    handleBlur,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      category: "regular",
      unit: "coin",
      quantity: 0,
      reason: "",
      ...initialValues,
    },
    onSubmit: async (formValues) => {
      await createBalanceTransaction(formValues);
      onSuccess?.();
      setSubmitting(false);
      resetForm();
    },
    validationSchema: balanceTransactionFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Form>
      <TextField
        type="number"
        name="quantity"
        required
        placeholder={"Quantité"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.quantity}
        hasError={touched.quantity && !!errors.quantity}
        // errorMessage={errors.quantity}
      />
      <TextField
        type="text"
        name="reason"
        required
        placeholder={"Note explicative"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.reason}
        hasError={touched.reason && !!errors.reason}
        // errorMessage={errors.reason}
      />
      <Button
        type="submit"
        disabled={!isValid || isLoading || isSubmitting}
        onClick={handleSubmit}
      >
        Créditer
      </Button>
    </Form>
  );
};

const UserPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user, getUser, isLoading } = useUser({ id });
  const [
    isNewBalanceTransactionModalIsOpen,
    setIsNewBalanceTransactionModalIsOpen,
  ] = useState<boolean>(false);
  const balanceTransactionRef = useRef();

  const refreshAll = () => {
    getUser();
    // @ts-ignore
    balanceTransactionRef.current?.refresh?.();
  };

  useEffect(refreshAll, [id]);

  return (
    <PageContainer
      style={{ display: "flex", flexDirection: "column", gap: 20 }}
    >
      {!isLoading && (
        <>
          <h1>
            {user.first_name} {user.last_name}
          </h1>
          <InformationContainer>
            <div>
              <Information Label={"ID"}>
                <Caption copy>{user.id}</Caption>
              </Information>
              <Information Label={"Email"}>{user.email}</Information>
              <Information Label={"Prénom"}>{user.first_name}</Information>
              <Information Label={"Nom"}>{user.last_name}</Information>
              <Information Label={"Role"}>
                <UserRoleCaption className={user.role}>
                  {t(`enums.user.role.${user.role}`)}
                </UserRoleCaption>
              </Information>
              <Information Label={"RPPS"}>
                <Caption copy>{user.professional_number}</Caption>
              </Information>
              <Information Label={"Date de naissance"}>
                {user.birth_date &&
                  new Date(user.birth_date).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
              </Information>
            </div>
            <div>
              <Information Label={"Actif"}>
                {user.disabled ? "Non" : "Oui"}
              </Information>
              <Information Label={"Date Inscription"}>
                {new Date(user.created_at).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Information>
              <Information Label={"Dernière connexion"}>
                {user.current_sign_in_at &&
                  new Date(user.current_sign_in_at).toLocaleDateString(
                    "fr-FR",
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
              </Information>
              <Information Label={"Abonnement"}>
                <UserPlanCaption className={user.plan}>
                  {t(`enums.user.plan.${user.plan}`)}
                </UserPlanCaption>
              </Information>
              <Information Label={"Crédits"}>{user.balance}</Information>
              <Information Label={"Stripe"}>
                <a
                  target="_blank"
                  href={STRIPE_ROUTES.CUSTOMERS(user.stripe_customer_id)}
                >
                  {user.stripe_customer_id}
                </a>
              </Information>
              <Information Label={"Code de parrainage"}>
                <Caption
                  copy
                  copyContent={`${
                    window.location.origin
                  }${ROUTES.SIGNUP()}?sponsor_code=${user.sponsorship_code}`}
                >
                  {user.sponsorship_code}
                </Caption>
              </Information>
              <Information Label={"Parrainé par"}>
                <Link to={ROUTES.USERS(user.sponsor_id)}>
                  {user.sponsor_id}
                </Link>
              </Information>
            </div>
          </InformationContainer>
        </>
      )}
      <div>
        <h2>Transactions</h2>
        <Button
          onClick={() => setIsNewBalanceTransactionModalIsOpen(true)}
          style={{
            margin: "0 auto",
          }}
          disabled={["admin", "registered"].includes(user?.role)}
        >
          Ajuster le solde
        </Button>
      </div>
      {/* @ts-ignore */}
      <Modal
        withPadding
        isOpen={isNewBalanceTransactionModalIsOpen}
        onRequestClose={() => setIsNewBalanceTransactionModalIsOpen(false)}
        overlay={true}
        withWrapper={true}
      >
        <h2>Ajuster le solde</h2>
        <BalanceTransactionForm
          onSuccess={() => {
            setTimeout(refreshAll, 200);
            setIsNewBalanceTransactionModalIsOpen(false);
          }}
          initialValues={{
            user_id: +id,
          }}
        />
      </Modal>
      <BalanceTransactionsTable
        ref={balanceTransactionRef}
        payload={{
          filters: {
            user_id: [id],
          },
          sorting: [
            {
              name: "created_at",
              direction: "desc",
            },
          ],
        }}
      />
    </PageContainer>
  );
};

export default UserPage;
