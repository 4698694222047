import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import {PageContainer} from '../../../_ui/page';
import {useDeleteQuote, useQuotes} from '../../../../_hooks/quote/use-quotes';
import Button from '../../../_ui/button';
import Modal from '../../../_ui/modal';
import {APIQuote} from '../../../../_services/api/_helpers/api-types';
import {useState} from 'react';
import QuoteForm from './QuoteForm';
import {ReactComponent as TrashIcon} from '../../../../_icons/trash.svg'
import { APISortingObject } from '../../../../_services/api/_helpers/api-list-types';
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import PaginationDisplay from '../../../_ui/pagination';

const DeleteCell = ({id, onDelete}) => {
  const {deleteQuote} = useDeleteQuote();

  return (
    <TrashIcon
      color={"#FF453A"}
      onClick={async () => {
        if (id && window.confirm("Voulez-vous vraiment supprimer cet élément?")) {
          await deleteQuote(id);
          onDelete?.();
        }
      }}
      height={"100%"}
    />
  );
};

const QuotesTable = () => {
  const [isModalOpen, setIsModalOpen] = useState<false | Partial<APIQuote>>(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<APISortingObject>([
    {
      name: "id",
      direction: "desc",
    },
  ]);
  const { quotes, getQuotes, isLoading } = useQuotes({
    page_size: 20,
    page_number: currentPage,
    filters: {},
    sorting: sorting,
  });

  const data = {nodes: quotes?.list || [], pageInfo:{totalPages:quotes&&(Math.ceil(quotes.total_count/quotes.page_size)||0)}};

  const refreshQuotes = () => setTimeout(getQuotes, 200);

  // -------- Pagination / Sorting /
  
  const pagination = usePagination(
    data,
    {
      state: {
        page: 0,
        size: 20
      },
      onChange: onPaginationChange,
    },
    { isServer: true }
  );

  function onPaginationChange(action, state) {
    // refresh page with payload page value = action.payload.page +1
    setCurrentPage(action.payload.page + 1);
  }

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {},
      isServer: true,
    }
  );

  function onSortChange(action, state) {
    // refresh page with search state value = state.sortKey / state.reverse
    setSorting([
      {
        name: state.sortKey,
        direction: state.reverse ? "desc" : "asc",
      },
    ]);
  }

  // -- End Pagination / Sorting / Search

  const handleCellClick = setIsModalOpen;

  const COLUMNS = (handleCellClick, onDelete) => [
    {
      label: 'ID',
      renderCell: (item) => item.id.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "id" },
    },
    {
      label: 'Contenu',
      renderCell: (item) => item.text,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "text" },
    },
    {
      label: 'Diffusion',
      renderCell: (item) => item.diffusion_date,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
    },
    {
      label: 'Créée le',
      renderCell: (item) =>
        new Date(item.created_at).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "created_at" },
    },
    {
      label: 'Modifiée le',
      renderCell: (item) =>
        new Date(item.updated_at).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "updated_at" },
    },
    {
      label: '',
      renderCell: (item) => (
        <DeleteCell id={item.id} onDelete={onDelete}/>
      ),
    },
  ];

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 50px 1fr 120px minmax(120px, 180px) minmax(120px, 180px) 50px;
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={() => setIsModalOpen({})}
        >
          Ajouter
        </Button>
      </div>
      {isLoading ? <span className="spin-loader"></span> : <div style={{ textAlign: "left" }}>
        <CompactTable
          columns={COLUMNS(handleCellClick, refreshQuotes)}
          data={data}
          theme={theme}
          sort={sort}
          pagination={pagination}
          layout={{
            custom: true,
            horizontalScroll: true,
            fixedHeader: true,
          }}
        />
      </div>}
      
      <br />
      <PaginationDisplay pagination={pagination} data={data}/>
      {isModalOpen && (
        // @ts-ignore
        <Modal
          withPadding
          isOpen={!!isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          overlay={true}
          withWrapper={true}
        >
          <h2>{isModalOpen.id ? 'Modifier une citation' : 'Création d\'une citation'}</h2>
          <QuoteForm
            initialValues={isModalOpen}
            onSuccess={() => {
              refreshQuotes();
              setIsModalOpen(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};

const QuotesPage = () => (
  <PageContainer
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 20,
    }}
  >
    <h1>Citations</h1>
    <QuotesTable/>
  </PageContainer>
);

export default QuotesPage;