import type { Pagination } from "@table-library/react-table-library/types/pagination";

const PaginationDisplay = ({
  pagination,
  data,
}: {
  pagination: Pagination<any>;
  data: any;
}) => {
  return (
    <>
      {data.pageInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>Nombre de pages : {data.pageInfo.totalPages}</span>

          <span>
            Page:{" "}
            {Array.from(Array(data.pageInfo.totalPages).keys()).map((_, index) => (
              <button
                key={index}
                type="button"
                style={{
                  fontWeight:
                    pagination.state.page === index ? "bold" : "normal",
                }}
                onClick={() => pagination.fns.onSetPage(index)}
              >
                {index + 1}
              </button>
            ))}
          </span>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Nombre de pages : {pagination.state.getTotalPages(data.nodes)}</span>

          <span>
            Page:{" "}
            {pagination.state.getPages(data.nodes).map((_, index) => (
              <button
                key={index}
                type="button"
                style={{
                  fontWeight:
                    pagination.state.page === index ? "bold" : "normal",
                }}
                onClick={() => pagination.fns.onSetPage(index)}
              >
                {index + 1}
              </button>
            ))}
          </span>
        </div>
      )}
    </>
  );
};

export default PaginationDisplay;
