import styled from "styled-components";
import { BackButton } from "../user";
import { ReactComponent as NavigateBeforeIcon } from "../../../../_icons/navigate_before.svg";
import { useNavigate } from "react-router-dom";
import Caption from "../../../_ui/caption";
import { ROUTES } from "../../../../router/routes";
import { useAuthContext } from "../../../../_contexts/auth";

const Container = styled.div`
  padding: 20px;
  overflow-y: scroll;
  text-align: left;
  h1 {
    span {
      color: black;
      font-weight: 600;
      font-size: 17px;
    }
  }
  h2 {
    span {
      color: black;
      font-weight: 500;
    }
  }
  p {
    text-align: left;
    font-family: "Noto Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    margin-bottom: 1rem;
  }
`;

const mentoring_text = `
Programme de Parrainage NEMO
👤 Parraine un collègue et profite d'avantages exclusifs !
NEMO, cʼest encore mieux à plusieurs ! Invite un confrère à rejoindre notre communauté et bénéficiez tous les deux dʼune
récompense.
🎉 1 parrainage  1 mois offert pour toi et ton filleul !
✅ Comment ça marche ?
󾠮 Partage ton lien personnel de parrainage
Chaque utilisateur NEMO possède un lien unique. Envoie-le à ton collègue via WhatsApp, email ou tout autre moyen.
󾠯 Ton collègue crée son compte et sʼabonne
Dès quʼil valide son abonnement, il devient officiellement ton filleul.
󾠰 Vous gagnez tous les deux une récompense 🎁
Une fois son inscription confirmée, vous obtenez chacun
1 mois offert !
➡ Parraine autant de collègues que tu veux ! Plus tu parraines, plus tu gagnes !
📞 Partage ton lien en un clic !
🔗 Ton lien de parrainage unique :
[https://nemo.henritrip.fr/signup?sponsor_code=XXXXXX (lien du parrainage cliquable)
📢 Plus ton lien est partagé, plus tu accumules des avantages !
🛠 Conditions du programme de parrainage
Avant de commencer, voici ce quʼil faut savoir :
✔ Ton filleul doit être un nouvel utilisateur de NEMO.
✔ La récompense est activée après sa première souscription.
✔ Tu peux parrainer autant de collègues que tu veux et cumuler les avantages.
✔ Toute tentative de fraude peut entraîner lʼannulation des récompenses.
📜 Voir les conditions complètes (lien ramenant vers les conditions générales du programme de parrainage)
🚀 Lance-toi dès maintenant !
🌟 Partage ton lien et fais découvrir NEMO à tes confrères dès aujourdʼhui !Programme de Parrainage NEMO1

`;

export const Mentoring_html = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  return (
    <div>
      <h1>Programme de Parrainage NEMO</h1>
      <br />
      <h2>👤 Parraine un coll&egrave;gue et profite d'avantages exclusifs !</h2>
      <p>
        <span>
          NEMO, cʼest encore mieux &agrave; plusieurs ! Invite un
          confr&egrave;re &agrave; rejoindre notre communaut&eacute; et
          b&eacute;n&eacute;ficiez tous les deux dʼune r&eacute;compense.
        </span>
      </p>
      <p>
        <b>🎉 1 parrainage = 1 mois offert pour toi et ton filleul !</b>
      </p>
      <h2>✅ Comment &ccedil;a marche ?</h2>
      <ul>
        <li>
          <p>
            <b>1) Partage ton lien personnel de parrainage</b>
            <br />
            <span>
              Chaque utilisateur NEMO poss&egrave;de un lien unique. Envoie-le
              &agrave; ton coll&egrave;gue via WhatsApp, email ou tout autre
              moyen.
            </span>
          </p>
        </li>
        <li>
          <p>
            <b>2) Ton coll&egrave;gue cr&eacute;e son compte et sʼabonne</b>
            <br />
            <span>
              D&egrave;s quʼil valide son abonnement, il devient officiellement
              ton filleul.
            </span>
          </p>
        </li>
        <li>
          <p>
            <b>3) Vous gagnez tous les deux une r&eacute;compense 🎁</b>
            <br />
            <span>
              Une fois son inscription confirm&eacute;e, vous obtenez chacun 1
              mois offert !<br />
            </span>
          </p>
        </li>
        <li>
          <p>
            <b>
              ➡ Parraine autant de coll&egrave;gues que tu veux ! Plus tu
              parraines, plus tu gagnes !
            </b>
          </p>
        </li>
      </ul>
      <br />
      <h2>📞 Partage ton lien en un clic !</h2>
      <p>
        <b>🔗 Ton lien de parrainage unique :</b>
      </p>

      <Caption
        copy
        copyContent={`${
          window.location.origin
        }${ROUTES.SIGNUP()}?sponsor_code=${user.sponsorship_code}`}
      >
        {user.sponsorship_code}
      </Caption>

      <p>
        <span>
          📢 Plus ton lien est partag&eacute;, plus tu accumules des avantages !
        </span>
      </p>
      <br />
      <h2>🛠 Conditions du programme de parrainage</h2>
      <p>
        <span>Avant de commencer, voici ce quʼil faut savoir :</span>
      </p>
      <p>
        <span>
          ✔ Ton filleul doit&ecirc;tre un <b>nouvel utilisateur</b> de NEMO.
        </span>
      </p>
      <p>
        <span>
          ✔ La r&eacute;compense est{" "}
          <b>activ&eacute;e apr&egrave;s sa premi&egrave;re souscription</b>.
        </span>
      </p>
      <p>
        <span>
          ✔ Tu peux parrainer <b>autant de coll&egrave;gues que tu veux</b> et
          cumuler les avantages.
        </span>
      </p>
      <p>
        <span>
          ✔ Toute tentative de fraude peut entra&icirc;ner lʼannulation des
          r&eacute;compenses.
        </span>
      </p>

      <p>
        <span>
          <b>
            <u style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.MENTORING_LEGALS())}>📜 Voir les conditions compl&egrave;tes</u>
          </b>
        </span>
      </p>
      <br />
      <h2>🚀 Lance-toi d&egrave;s maintenant !</h2>
      <p>
        <b>
          🌟 Partage ton lien et fais d&eacute;couvrir NEMO &agrave; tes
          confr&egrave;res d&egrave;s aujourdʼhui !
        </b>
      </p>
    </div>
  );
};

const MentoringPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <NavigateBeforeIcon style={{ stroke: "#8E8E8E" }} />
      </BackButton>
      <Mentoring_html />
    </Container>
  );
};

export default MentoringPage;
