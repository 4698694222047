import { useUsers } from "../../../../_hooks/user/use-users";
import * as React from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { PageContainer } from "../../../_ui/page";
import { ROUTES } from "../../../../router/routes";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { STRIPE_ROUTES } from "../../../../_services/stripe/routes";
import { UserRoleCaption, UserPlanCaption } from "../_commons/captions";
import { useTranslation } from "react-i18next";
import type { APISortingObject } from "../../../../_services/api/_helpers/api-list-types";
import { TextField } from "../../../_ui/form";
import PaginationDisplay from "../../../_ui/pagination";

// Sort

const UsersTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<APISortingObject>([
    {
      name: "id",
      direction: "desc",
    },
  ]);
  const [search, setSearch] = React.useState("");

  const { users, isLoading } = useUsers({
    page_size: 20,
    page_number: currentPage,
    filters: { search: [search] },
    sorting: sorting,
  });

  const data = {nodes: users?.list || [], pageInfo:{totalPages:users&&(Math.ceil(users.total_count/users.page_size)||0)}};

  
  // -------- Pagination / Sorting / Search
  
  const pagination = usePagination(
    data,
    {
      state: {
        page: 0,
        size: 20
      },
      onChange: onPaginationChange,
    },
    { isServer: true }
  );

  function onPaginationChange(action, state) {
    // refresh page with payload page value = action.payload.page +1
    setCurrentPage(action.payload.page + 1);
  }
  
  const handleSearch = (event) => {
    // refresh page with input search value = event.target.value
    setSearch(event.target.value);
  };

  const onInputChange = React.useMemo(
    () => debounce(handleSearch, 1000),
    [],
  );

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {},
      isServer: true,
    }
  );

  function onSortChange(action, state) {
    // refresh page with search state value = state.sortKey / state.reverse
    setSorting([
      {
        name: state.sortKey,
        direction: state.reverse ? "desc" : "asc",
      },
    ]);
  }

  // -- End Pagination / Sorting / Search

  const handleCellClick = (data) => {
    navigate(ROUTES.USERS(data.id));
  };

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 60px minmax(180px, 1fr) 120px 60px 140px repeat(2, minmax(100px, 100px)) 80px 110px 80px repeat(2, minmax(60px, 60px));
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  const COLUMNS = (handleCellClick, t) => [
    // {
    //   label: "ID",
    //   renderCell: (item) => item.id.toString(),
    //   cellProps: {
    //     onClick: (item) => () => handleCellClick(item),
    //   },
    //   sort: { sortKey: "id" },
    // },
    {
      label: "ID",
      renderCell: (item) => item.id,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "id" },
    },
    {
      label: "Email",
      renderCell: (item) => item.email,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "email" },
    },
    {
      label: "Role",
      renderCell: (item) => (
        <UserRoleCaption className={item.role}>
          {t(`enums.user.role.${item.role}`)}
        </UserRoleCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "role" },
    },
    {
      label: "Actif",
      renderCell: (item) => {return item.disabled?'Non':'Oui'},
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "disabled" },
    },
    {
      label: "RPPS",
      renderCell: (item) => item.professional_number,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "professional_number" },
    },
    {
      label: "Prénom",
      renderCell: (item) => item.first_name,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "first_name" },
    },
    {
      label: "Nom",
      renderCell: (item) => item.last_name,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "last_name" },
    },
    {
      label: "Crédits",
      renderCell: (item) => item.balance,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
    },
    {
      label: "Abonnement",
      renderCell: (item) => (
        <UserPlanCaption className={item.plan}>
          {t(`enums.user.plan.${item.plan}`)}
        </UserPlanCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "plan" },
    },
    {
      label: "Stripe",
      renderCell: (item) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={STRIPE_ROUTES.CUSTOMERS(item.stripe_customer_id)}
        >
          {item.stripe_customer_id}
        </a>
      ),
      sort: { sortKey: "stripe_customer_id" },
    },
    {
      label: "Date Inscription",
      renderCell: (item) =>
        new Date(item.created_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "created_at" },
    },
    {
      label: "Dernière connexion",
      renderCell: (item) =>
        item.current_sign_in_at &&
        new Date(item.current_sign_in_at).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
      },
      sort: { sortKey: "current_sign_in_at" },
    },
  ];

  return (
    <>
      <TextField
          type="text"
          label="Rechercher un utilisateur"
          placeholder={""}
          onChange={(e) => {
            onInputChange(e);
          }}
        />
      <br />
      {isLoading ? <span className="spin-loader"></span> : <div style={{ textAlign: "left" }}>
        <CompactTable
          columns={COLUMNS(handleCellClick, t)}
          data={data}
          theme={theme}
          sort={sort}
          pagination={pagination}
          layout={{
            custom: true,
            horizontalScroll: true,
            fixedHeader: true,
          }}
        />
      </div>}
      
      <br />
      <PaginationDisplay pagination={pagination} data={data}/>
    </>
  );
};

const UsersPage = () => (
  <PageContainer
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: 20,
    }}
  >
    <h1>Utilisateurs</h1>
    <UsersTable />
  </PageContainer>
);

export default UsersPage;
