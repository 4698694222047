import styled from "styled-components";
import { BackButton } from "../user";
import { ReactComponent as NavigateBeforeIcon } from "../../../../_icons/navigate_before.svg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../_contexts/auth";

const Container = styled.div`
  padding: 20px;
  overflow-y: scroll;
  text-align: left;
  h1 {
    span {
      color: black;
      font-weight: 600;
      font-size: 17px;
    }
  }
  h2 {
    span {
      color: black;
      font-weight: 500;
    }
  }
  p {
    text-align: left;
    font-family: "Noto Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: circle;
    list-style-position: inside;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
`;

const faq_text = `
FOIRE AUX QUESTIONS
DÉCOUVERTE
Pourquoi NEMO ?
NEMO est lʼacronyme de Neuro, Musculo-tendineux et Ostéo-articulaire, représentant les principales catégories de
pathologies prises en charge par les kinésithérapeutes. Ce nom reflète lʼessence de lʼapplication : un outil conçu pour
accompagner les kinés dans leur processus diagnostique.
Qui peut utiliser NEMO ?
NEMO est exclusivement réservé aux kinésithérapeutes diplômés et aux étudiants en kinésithérapie. Il sʼagit dʼune
solution pensée par des kinés pour des kinés.
À quel type de kinésithérapeute sʼadresse NEMO ?
NEMO est conçu pour tous les kinés, quels que soient leur expérience et leur domaine d'expertise. Il intervient comme un
outil dʼaide au diagnostic et un second avis fiable. Il peut être utile dans les situations suivantes :
Après un bilan initial, en cas de doute sur un diagnostic.
En complément des connaissances acquises, pour approfondir une recherche.
Lorsque lʼévolution dʼun traitement est incertaine, nécessitant une réévaluation du diagnostic.
Pour se conforter dans un diagnostic avant dʼorienter un patient vers une prise en charge spécifique.
En tant quʼoutil pédagogique pour les étudiants et jeunes diplômés.
Pour les praticiens expérimentés souhaitant mettre à jour leurs connaissances.
NEMO nʼest pas conçu pour être utilisé en temps réel lors dʼun bilan avec un patient, mais si lʼinterface vous convient, vous
pouvez lʼintégrer dans votre pratique.
La recherche de diagnostic avec NEMO est-elle fiable ?
NEMO sʼappuie sur des sources scientifiques vérifiées et une base de données actualisée pour proposer des
suggestions pertinentes. Lʼalgorithme classe les pathologies selon les données cliniques que vous renseignez et le tracé
effectué sur lʼécorché de lʼapplication. Toutefois, le résultat est indicatif et dépend de la précision des informations saisies.
NEMO utilise-t-il des sources fiables ?
Oui. Lʼoutil est construit sur des données issues de la littérature scientifique et médicale. Une veille régulière est
effectuée afin de garantir la pertinence des résultats et de tenir compte des évolutions scientifiques.
NEMO établit-il un diagnostic ?
Non. NEMO fournit une liste de pathologies potentielles, triées selon leur compatibilité avec les symptômes renseignés. Il
ne remplace pas lʼexpertise clinique du kinésithérapeute.
Comment fonctionne NEMO ?
NEMO dispose dʼune base de données de plus de 650 pathologies. Lʼoutil repose sur :
 Une localisation des symptômes via un tracé sur un modèle anatomique.
 Une sélection de critères cliniques correspondant aux observations du patient.
 Un algorithme de tri qui priorise les pathologies en fonction des éléments renseignés.
MODÈLE ÉCONOMIQUE & ABONNEMENT
Que sont les jetons ?
Les jetons sont une monnaie interne permettant aux utilisateurs de la version freemium dʼaccéder aux fonctionnalités de
NEMO sans abonnement payant.
À quoi servent les jetons et comment en obtenir ?
Un utilisateur freemium reçoit chaque mois 10 jetons.
Une recherche diagnostique coûte 4 jetons.
Une mise à jour dʼune recherche coûte également 4 jetons.
Quelle est la différence entre un membre freemium et un membre premium ?
Freemium  Accès limité par le nombre de jetons disponibles.
Premium  Accès illimité aux recherches et aux mises à jour, sans restriction.
Toutes les fonctionnalités sont accessibles aux deux types dʼutilisateurs, mais les membres premium bénéficient dʼun
usage sans contrainte.
Pourquoi clôturer une recherche de diagnostic ?
Clôturer une recherche permet dʼoptimiser lʼalgorithme et dʼaméliorer la pertinence des suggestions. Ces informations
anonymisées contribuent à affiner lʼoutil pour lʼensemble de la communauté.
Lʼabonnement NEMO est-il déductible fiscalement ?
Oui. NEMO étant un outil professionnel, les frais dʼabonnement peuvent être déclarés comme dépenses professionnelles.
NEMO est-il un dispositif médical ?
Non, NEMO nʼa pas le statut de dispositif médical. Il sʼagit dʼun outil dʼaide à la décision, basé sur des référentiels
scientifiques, sans valeur de diagnostic médical.
UTILISATION TECHNIQUE
Comment utiliser NEMO ?
NEMO est une Web App, accessible via un navigateur mobile ou desktop. Il nʼest pas obligatoirement nécessaire dʼinstaller
une application native. Mais pour une meilleure ergonomie, nous recommandons dʼajouter NEMO à votre écran dʼaccueil en
installant lʼapplication.
Si vous utiliser Google Chrome, cliquez sur “Paramètresˮ puis “Ajouter à lʼécran dʼaccueilˮ, vous pouvez alors installer
lʼapplication sur votre mobile.
Comment effectuer une recherche ?
Cliquez sur “Démarrer un diagnosticˮ.
Sélectionnez la zone affectée sur lʼécorché.
Ajoutez les signes cliniques pertinents.
Obtenez une liste de pathologies classées selon les critères renseignés.
Puis-je reprendre une recherche arrêtée ?
Oui, en cliquant sur “Diagnostic en coursˮ. Pour les membres freemium, reprendre une recherche nécessite un crédit
supplémentaire, tandis que les membres premium peuvent les consulter sans restriction.
PARRAINAGE
Puis-je parrainer plusieurs kinés ?
Oui, et chaque parrainage vous offre un mois dʼabonnement gratuit.
Abonné mensuel : votre prochain mois est offert.
Abonné annuel : un mois supplémentaire est ajouté à votre période dʼabonnement.
Le filleul bénéficie également dʼun mois gratuit à la suite d'une souscription à un abonnement premium.
SERVICE CLIENT & GESTION DU COMPTE
Comment résilier mon abonnement ?
Lʼabonnement est sans engagement et peut être résilié à tout moment via lʼonglet “Mes informationsˮ > “Gérer mon
abonnementˮ. Vous conservez lʼaccès premium jusquʼà la fin de votre période dʼabonnement, après quoi vous basculez en
freemium.
Puis-je supprimer définitivement mon compte ?
Oui. En supprimant votre compte, toutes vos données personnelles seront effacées. Il ne sera plus possible de récupérer
vos anciennes recherches.
Jʼai un problème à lʼinscription, que faire ?
Seuls les kinésithérapeutes ayant un numéro RPPS et les étudiants en kinésithérapie peuvent sʼinscrire.
Pour les jeunes diplômés, un délai de mise à jour de la base RPPS peut entraîner un refus temporaire.
La validation finale de votre inscription peut prendre jusquʼà 48 heures.
Si vous pensez être éligible mais rencontrez un problème, contactez-nous via le formulaire de support.
Comment signaler une erreur ou une pathologie manquante ?
Vous pouvez utiliser le formulaire de support pour signaler une erreur ou proposer une nouvelle pathologie. Nos équipes
analyseront votre retour avant une éventuelle intégration.
BESOIN DʼAIDE ?
Pour toute autre question, contactez-nous via hello@nemoapp.fr. ou le formulaire de support ou alors directement à
partir de lʼapplication NEMO via WhatsApp.


`;

export const Faq_html = () => {
  const { user } = useAuthContext();
  return (
    <div>
      <h1>FOIRE AUX QUESTIONS</h1>
      <h2>DÉCOUVERTE</h2>
      <h3>Pourquoi NEMO ?</h3>
      <p>
        <span>
          NEMO est lʼacronyme de Neuro,{" "}
          <b>Musculo-tendineux et Ostéo-articulaire</b>, représentant les
          principales catégories de pathologies prises en charge par les
          kinésithérapeutes. Ce nom reflète lʼessence de lʼapplication : un
          outil conçu pour accompagner les kinés dans leur processus
          diagnostique.
        </span>
      </p>
      <h3>Qui peut utiliser NEMO ?</h3>
      <p>
        <span>
          NEMO est exclusivement réservé aux{" "}
          <b>kinésithérapeutes diplômés et aux étudiants en kinésithérapie.</b>{" "}
          Il sʼagit dʼune solution pensée par des kinés pour des kinés.
        </span>
      </p>
      <h3>À quel type de kinésithérapeute sʼadresse NEMO ?</h3>

      <p>
        <span>
          NEMO est conçu pour tous les kinés, quels que soient leur expérience
          et leur domaine d'expertise. Il intervient comme un
          <b>outil dʼaide au diagnostic</b> et un <b>second avis</b> fiable. Il
          peut être utile dans les situations suivantes :
        </span>
      </p>
      <ul>
        <li>
          <span>
            Après un <b>bilan initial</b>, en cas de doute sur un diagnostic.
          </span>
        </li>
        <li>
          <span>
            En complément des <b>connaissances acquises</b>, pour approfondir
            une recherche.
          </span>
        </li>
        <li>
          <span>
            Lorsque <b>lʼévolution dʼun traitement est incertaine</b>,
            nécessitant une réévaluation du diagnostic.
          </span>
        </li>
        <li>
          <span>
            Pour <b>se conforter dans un diagnostic</b> avant dʼorienter un
            patient vers une prise en charge spécifique.
          </span>
        </li>
        <li>
          <span>
            En tant quʼ<b>outil pédagogique</b> pour les étudiants et jeunes
            diplômés.
          </span>
        </li>
        <li>
          <span>
            Pour les praticiens expérimentés souhaitant{" "}
            <b>mettre à jour leurs connaissances.</b>
          </span>
        </li>
      </ul>
      <p>
        <span>
          NEMO nʼest pas conçu pour être utilisé en <b>temps réel</b> lors dʼun
          bilan avec un patient, mais si lʼinterface vous convient, vous pouvez
          lʼintégrer dans votre pratique.
        </span>
      </p>

      <h3>La recherche de diagnostic avec NEMO est-elle fiable ?</h3>
      <p>
        <span>
          NEMO sʼappuie sur des <b>sources scientifiques vérifiées</b> et une{" "}
          <b>base de données actualisée</b> pour proposer des suggestions
          pertinentes. Lʼalgorithme classe les pathologies selon les données
          cliniques que vous renseignez et le tracé effectué sur lʼécorché de
          lʼapplication. Toutefois, le résultat est <b>indicatif</b> et dépend
          de la précision des informations saisies.
        </span>
      </p>

      <h3>NEMO utilise-t-il des sources fiables ?</h3>

      <p>
        <span>
          Oui. Lʼoutil est construit sur des{" "}
          <b>données issues de la littérature scientifique et médicale</b>. Une
          veille régulière est effectuée afin de garantir la pertinence des
          résultats et de tenir compte des évolutions scientifiques.
        </span>
      </p>

      <h3>NEMO établit-il un diagnostic ?</h3>

      <p>
        <span>
          Non. NEMO fournit une <b>liste de pathologies potentielles</b>, triées
          selon leur compatibilité avec les symptômes renseignés. Il ne remplace
          pas lʼexpertise clinique du kinésithérapeute.
        </span>
      </p>

      <h3>Comment fonctionne NEMO ?</h3>

      <p>
        <span>
          NEMO dispose dʼune base de données de plus de 650 pathologies. Lʼoutil
          repose sur :
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "decimal" }}>
          <span>
            <b>Une localisation des symptômes</b> via un tracé sur un modèle
            anatomique.
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span>
            <b>Une sélection de critères cliniques</b> correspondant aux
            observations du patient
          </span>
        </li>
        <li style={{ listStyleType: "decimal" }}>
          <span>
            <b>Un algorithme de tri</b> qui priorise les pathologies en fonction
            des éléments renseignés.
          </span>
        </li>
      </ul>

      <h2>MODÈLE ÉCONOMIQUE & ABONNEMENT</h2>

      <h3>Que sont les jetons ? </h3>

      <p>
        <span>
          Les jetons sont une <b>monnaie interne</b> permettant aux utilisateurs
          de la version freemium dʼaccéder aux fonctionnalités de NEMO sans
          abonnement payant.
        </span>
      </p>

      <h3>À quoi servent les jetons et comment en obtenir ?</h3>

      <ul>
        <li>
          <span>
            <b>Un utilisateur freemium reçoit chaque mois 10 jetons.</b>
          </span>
        </li>
        <li>
          <span>
            <b>Une recherche diagnostique coûte 4 jetons.</b>
          </span>
        </li>
        <li>
          <span>
            <b>Une mise à jour dʼune recherche coûte également 4 jetons.</b>
          </span>
        </li>
      </ul>

      <h3>
        Quelle est la différence entre un membre freemium et un membre premium ?
      </h3>
      <ul>
        <li>
          <span>
            <b>Freemium :</b> Accès limité par le nombre de jetons disponibles.
          </span>
        </li>
        <li>
          <span>
            <b>Premium :</b> Accès illimité aux recherches et aux mises à jour,
            sans restriction.
          </span>
        </li>
      </ul>
      <p>
        <span>
          Toutes les fonctionnalités sont accessibles aux deux types
          dʼutilisateurs, mais les membres premium bénéficient dʼun usage sans
          contrainte.
        </span>
      </p>

      <h3>Pourquoi clôturer une recherche de diagnostic ?</h3>

      <p>
        <span>
          Clôturer une recherche permet <b>dʼoptimiser lʼalgorithme</b> et{" "}
          <b>dʼaméliorer la pertinence des suggestions</b>. Ces informations
          anonymisées contribuent à affiner lʼoutil pour lʼensemble de la
          communauté.
        </span>
      </p>

      <h3>Lʼabonnement NEMO est-il déductible fiscalement ?</h3>

      <p>
        <span>
          Oui. NEMO étant un outil professionnel, les frais dʼabonnement peuvent
          être <b>déclarés comme dépenses professionnelles.</b>
        </span>
      </p>

      <p>
        <span>
          Non, NEMO nʼa pas le statut de dispositif médical. Il sʼagit{" "}
          <b>dʼun outil dʼaide à la décision</b>, basé sur des référentiels
          scientifiques, sans valeur de diagnostic médical.
        </span>
      </p>

      <h2>UTILISATION TECHNIQUE</h2>

      <h3>Comment utiliser NEMO ?</h3>

      <p>
        <span>
          NEMO est une <b>Web App</b>, accessible via un navigateur mobile ou
          desktop. Il nʼest pas obligatoirement nécessaire dʼinstaller une
          application native. Mais pour une meilleure ergonomie, nous
          recommandons dʼajouter NEMO à votre écran dʼaccueil en installant
          lʼapplication.
        </span>
      </p>
      <p>
        <span>
          Si vous utiliser Google Chrome, cliquez sur <b>“Paramètresˮ</b> puis{" "}
          <b>“Ajouter à lʼécran dʼaccueilˮ</b>, vous pouvez alors installer
          lʼapplication sur votre mobile.
        </span>
      </p>

      <h3>Comment effectuer une recherche ?</h3>

      <ul>
        <li>
          <span>
            Cliquez sur <b>“Démarrer un diagnosticˮ.</b>
          </span>
        </li>
        <li>
          <span>Sélectionnez la zone affectée sur lʼécorché.</span>
        </li>
        <li>
          <span>
            Ajoutez les <b>signes cliniques pertinents.</b>
          </span>
        </li>
        <li>
          <span>
            Obtenez une <b>liste de pathologies classées</b> selon les critères
            renseignés.
          </span>
        </li>
      </ul>

      <h3>Puis-je reprendre une recherche arrêtée ?</h3>

      <p>
        <span>
          Oui, en cliquant sur <b>“Diagnostic en coursˮ</b>. Pour les membres
          freemium, reprendre une recherche nécessite un{" "}
          <b>crédit supplémentaire</b>, tandis que les membres premium peuvent
          les consulter sans restriction.
        </span>
      </p>

      <h2>PARRAINAGE</h2>

      <h3>Puis-je parrainer plusieurs kinés ?</h3>

      <p>
        <span>
          Oui, et chaque parrainage vous offre{" "}
          <b>un mois dʼabonnement gratuit.</b>
        </span>
      </p>
      <ul>
        <li>
          <span>
            <b>Abonné mensuel :</b> votre prochain mois est offert.
          </span>
        </li>
        <li>
          <span>
            <b>Abonné annuel :</b> un mois supplémentaire est ajouté à votre
            période dʼabonnement.
          </span>
        </li>
      </ul>
      <p>
        <span>
          Le filleul bénéficie également dʼ<b>un mois gratuit</b> à la suite
          d'une souscription à un abonnement premium.
        </span>
      </p>

      <h2>SERVICE CLIENT & GESTION DU COMPTE</h2>

      <h3>Comment résilier mon abonnement ?</h3>

      <p>
        <span>
          Lʼabonnement est sans engagement et peut être résilié à tout moment
          via lʼonglet <b>“Mes informationsˮ - “Gérer mon abonnementˮ</b>. Vous
          conservez lʼaccès premium jusquʼà la fin de votre période
          dʼabonnement, après quoi vous basculez en freemium.
        </span>
      </p>

      <h3>Puis-je supprimer définitivement mon compte ?</h3>

      <p>
        <span>
          Oui. En supprimant votre compte,
          <b>toutes vos données personnelles seront effacées.</b> Il ne sera
          plus possible de récupérer vos anciennes recherches.
        </span>
      </p>

      <h3>Jʼai un problème à lʼinscription, que faire ?</h3>

      <ul>
        <li>
          <span>
            Seuls les kinésithérapeutes ayant <b>un numéro RPPS</b> et{" "}
            <b>les étudiants en kinésithérapie</b> peuvent sʼinscrire.
          </span>
        </li>
        <li>
          <span>
            Pour les jeunes diplômés, un délai de mise à jour de la base RPPS
            peut entraîner un refus temporaire.
          </span>
        </li>
        <li>
          <span>
            La validation finale de votre inscription peut prendre jusquʼà{" "}
            <b>48 heures.</b>
          </span>
        </li>
        <li>
          <span>
            Si vous pensez être éligible mais rencontrez un problème,
            contactez-nous via le{" "}
            <b>
              <u
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://tally.so/r/woKoON", "_blank");
                }}
              >
                formulaire de support
              </u>
            </b>
          </span>
        </li>
      </ul>

      <h3>Comment signaler une erreur ou une pathologie manquante ?</h3>

      <p>
        <span>
          Vous pouvez utiliser le{" "}
          <b>
            <u
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("https://tally.so/r/woKoON", "_blank");
              }}
            >
              formulaire de support
            </u>
          </b>{" "}
          pour signaler une erreur ou proposer une nouvelle pathologie. Nos
          équipes analyseront votre retour avant une éventuelle intégration.
        </span>
      </p>

      <h2>BESOIN DʼAIDE ?</h2>

      <p>
        <span>
          Pour toute autre question, contactez-nous via{" "}
          <a
            style={{
              color: "#636360",
              fontWeight: "700",
              fontSize: "13px",
              textDecoration: "underline",
            }}
            href="mailto:hello@nemoapp.fr"
          >
            hello@nemoapp.fr
          </a>
          . ou le{" "}
          <b>
            <u
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("https://tally.so/r/woKoON", "_blank");
              }}
            >
              formulaire de support
            </u>
          </b>{" "}
          ou alors directement à partir de lʼapplication NEMO via WhatsApp.
        </span>
      </p>
    </div>
  );
};

const FAQPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <NavigateBeforeIcon style={{ stroke: "#8E8E8E" }} />
      </BackButton>
      <Faq_html />
    </Container>
  );
};

export default FAQPage;
