import {PageContainer} from '../../../_ui/page';
import Human from '../../../_ui/human';
import {useRef, useState} from 'react';
import {useBodyParts} from '../../../../_hooks/body-part/use-body-parts';
import {PathologyAPIService} from '../../../../_services/api';
import {APIBodyPart, APIRequestPathologyMatch} from '../../../../_services/api/_helpers/api-types';
import FlexToolBox from '../../../_ui/tool-boxes/flex-tool-box';
import BodyPartToolBox from '../../../_ui/tool-boxes/body-part-tool-box';
import TabbedNavigation from '../../../_ui/tabbed-navigation';
import DrawActionButton from '../../../_ui/draw-action-button';
import {ReactComponent as ResetIcon} from '../../../../_icons/reset.svg';

const MIN_MATCH_PERCENT = 3;

const Draw = ({onBodyPartChange, bodyPart, onSearch, imageState, resetBodyParts}) => {
  const pictureRef = useRef();

  const getActions = () => {
    return pictureRef.current;
  };

  const cleanBodyParts = () => {
    // @ts-ignore
    getActions()?.clear();
    resetBodyParts()
  }
  

  return (
    <div
      style={{
        flex: 1,
        position: 'relative',
        maxHeight: '85svh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          rowGap: 4,
          columnGap: 20,
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
      >
        <BodyPartToolBox
          selectedBodyPart={bodyPart}
          // @ts-ignore
          setSelectedBodyPart={async (bp) => onBodyPartChange(bp, await getActions()?.processImage?.())}
        />
        <FlexToolBox
          getActions={getActions}
          // @ts-ignore
          onSearch={async () => onSearch(await getActions()?.processImage?.())}
        />
        <DrawActionButton
            style={{width: 'auto'}}
            $custombg='#DCDCD9'
            onClick={() => {
              cleanBodyParts()
            }}
          ><ResetIcon />
          Réinitialiser
        </DrawActionButton>
      </div>
      {bodyPart?.id && (
        <Human
          key={bodyPart.id}
          ref={pictureRef}
          imageStateEditor={imageState}
          bodyPart={bodyPart}
        />
      )}
    </div>
  );
};

const Results = ({results}) => {
  const {bodyParts} = useBodyParts();

  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 10}}>
      <h2>Résultats</h2>
      {!results && (
        <em>Effectuez une recherche via l'onglet 'Écorchés'</em>
      )}
      {results?.length === 0 && (
        <em>Aucun résultat</em>
      )}
      {
        results?.map((result, index) => {
          const percent = result.score * 100;
          return (
            <div key={index} style={{textAlign: 'left'}}>
              {percent.toFixed(2)}% - {result.pathology.text}
              <ol>
                {result.scores.map((score, i) => (
                  <li key={i}>
                    {bodyParts?.list?.find(bp => bp.id === score.pathology_blob.body_part_id)?.label} - {(score.score * 100).toFixed(2)}% 
                    {score.draw_area_score && score.match_score && score.proximity_score && 
                    <>(
                      <span style={{color:'red'}}>{(score.draw_area_score*100).toFixed(2)}%</span>/
                      <span style={{color:'blue'}}>{(score.match_score*100).toFixed(2)}%</span>/
                      <span style={{color:'green'}}>{(score.proximity_score*100).toFixed(2)}%</span>
                      )</>
                      }
                  </li>
                ))}
              </ol>
            </div>
          );
        })
      }
    </div>
  );
}

const SearchPage = () => {
  const [bodyPart, setBodyPart] = useState<APIBodyPart | null>(null);
  const [payload, setPayload] = useState<APIRequestPathologyMatch[]>([]);
  const [results, setResult] = useState<any>();
  const [imageStates, setImageStates] = useState<object>({});
  const [selectedTabKey, setSelectedTabKey] = useState<string>('draw');
  const currentBodyPart = bodyPart;

  const resetBodyParts = () => {
    // @ts-ignore
    setImageStates({});
  }

  const saveData = async (res) => {
    const newItems = new RegExp('[1-9]').test(res.binary_area) ? [{
      body_part_id: currentBodyPart.id,
      binary_area: res.binary_area,
    }] : [];

    await setPayload(prev => ([
      ...prev.filter((obj) => obj.body_part_id !== currentBodyPart.id),
      ...newItems,
    ]));
    setImageStates(prev => ({...prev, [currentBodyPart.id?.toString()]: res.image_state_editor}));
    return newItems;
  };

  const requestApi = async (res) => {
    const newItems = await saveData(res);

    const requestBody = [
      ...payload.filter((obj) => obj.body_part_id !== newItems[0]?.body_part_id),
      ...newItems
    ];

    if (requestBody.length <= 0) {
      return;
    }

    const resultsApi = await PathologyAPIService.getMatchesPathologies({
      data: requestBody,
      filters: {
        min_score: MIN_MATCH_PERCENT / 100.0,
      }
    });
    setResult(resultsApi);
    setSelectedTabKey('results');
  };

  const items = [
    {
      key: 'draw',
      element: <span>Écorchés</span>,
      Component: () => (
        <Draw
          onBodyPartChange={async (bodyPartValue, res) => {
            if (bodyPartValue?.id !== currentBodyPart?.id) {
              if (currentBodyPart) {
                await saveData(res);
              }
              setBodyPart(bodyPartValue);
            }
          }}
          bodyPart={currentBodyPart}
          onSearch={requestApi}
          imageState={imageStates?.[bodyPart?.id?.toString()]}
          resetBodyParts={resetBodyParts}
        />
      ),
      onClick: () => setSelectedTabKey('draw'),
    },
    {
      key: 'results',
      element: <span>Résultats</span>,
      Component: () => (
        <Results results={results}/>
      ),
      onClick: () => setSelectedTabKey('results'),
    }
  ];

  const currentItemIndex = items.findIndex(item => item.key === selectedTabKey);
  const CurrentComponent = items[currentItemIndex].Component;

  return (
    <PageContainer style={{display: 'flex', flexDirection: 'column'}}>
      <TabbedNavigation
        items={items}
        defaultActive={currentItemIndex}
      />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          minHeight: '90svh',
        }}
      >
        <CurrentComponent/>
      </div>
    </PageContainer>
  );
};

export default SearchPage;